const settingsTutorial = {
  _id: {
    $oid: "!",
  },
  userID: "!",
  createdAt: {
    $date: "2024-10-11T15:01:17.504Z",
  },
  updatedAt: {
    $date: "2024-12-19T09:53:04.311Z",
  },
  __v: 0,
  showNotes: false,
  zoom: 1,
  firstname: "Иван",
  middlename: "Иванович",
  lastname: "Малотазов",
  nickname: "Luchevik",
  changedTextColor: "#fefcdd",
  changedTextColorDefault: "#fefcdd",
  changedSegmentColor: "#dfbf68",
  changedSegmentColorDefault: "#dfbf68",
  rybaHeaders: [
    'Государственное бюджетное учреждение здравоохранения города Москвы "Московский многопрофильный научно-клинический центр имени С.П. Боткина" Департамента здравоохранения города Москвы\n125284, Москва, 2-й Боткинский пр-д, 5\n8 (499) 490-0303',
  ],
  autosaveOnCopy: true,
};

export default settingsTutorial;
