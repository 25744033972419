import TextareaAutosize from "react-textarea-autosize";
import Duplicate from "../../svg/Duplicate";
import ArrowUp from "../../svg/ArrowUp";
import ArrowDown from "../../svg/ArrowDown";
import DeleteIcon from "../../svg/DeleteIcon";
import { useState } from "react";

const ConstructorRedactor = ({
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  constructorsArray,
}) => {
  return (
    <>
      <div className="buttonContentDiv">
        Выберите конструктор/калькулятор: {"  "}
        <select
          className="constructorSelect"
          value={
            rybaObject.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].constructor
          }
          onChange={(e) => {
            let r = { ...rybaObject };
            r.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].constructor = e.target.value;
            r.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].title = e.target.value;

            setRybaObject(r);
          }}
        >
          {constructorsArray.map((o, index) => (
            <option value={o}>{o}</option>
          ))}
        </select>
        {constructor === "Калькулятор стеноза артерии" && 1}
      </div>
    </>
  );
};
export default ConstructorRedactor;
