import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";

const AddButton = ({
  organINDEX,
  segmentINDEX,
  rybaObject,
  setAddButtonOpen,
  updateRybaObject,
}) => {
  let p = 0;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [zakl, setZakl] = useState("");

  // enter handler
  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (title === "") return alert("введите название");
      updateRybaObject(title, content, zakl);
    }
    if (e.key === "Tab") {
      e.preventDefault();
    }
    if (e.key === "Escape") {
      setAddButtonOpen(false);
    }
  };

  return (
    <div className="popupContainer" key={(p += 1)}>
      <div
        className="popupContent"
        style={{
          width: "450px",
        }}
        key={(p += 1)}
      >
        <span>Название кнопки</span>
        <textarea
          autoFocus
          className="inputPromptTextarea"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onKeyDown={submitEnter}
        ></textarea>
        <span>Текст в описание</span>
        <span
          style={{
            fontSize: "14px",
            color: "grey",
          }}
        >
          Формулировка будет вставляться в описание
        </span>
        <textarea
          className="inputPromptTextarea"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          onKeyDown={submitEnter}
        ></textarea>
        <span>Текст в заключение</span>
        <span
          style={{
            fontSize: "14px",
            color: "grey",
          }}
        >
          Формулировка будет вставляться в заключение. Можно оставить пустой
        </span>
        <textarea
          className="inputPromptTextarea"
          value={zakl}
          onChange={(e) => {
            setZakl(e.target.value);
          }}
          onKeyDown={submitEnter}
        ></textarea>

        <button
          className="saveChanges"
          onClick={(e) => {
            if (title === "") return alert("введите название");
            updateRybaObject(title, content, zakl);
          }}
        >
          Создать (Enter)
        </button>

        <button
          className="closeTextEditWindow"
          onClick={() => {
            setAddButtonOpen(false);
          }}
        >
          Закрыть (Esc)
        </button>
      </div>
    </div>
  );
};
export default AddButton;
