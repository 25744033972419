import TextareaAutosize from "react-textarea-autosize";

const CheckboxRedactor = ({
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
}) => {
  return (
    <>
      <div className="buttonEditingSegment">
        <div className="buttonCONTENT">Текст кнопки</div>
        <div className="buttonZAKL">В заключение</div>
      </div>
      <div className="buttonEditingSegment">
        <div className="buttonCONTENT">
          <TextareaAutosize
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].content
            }
            onChange={(e) => {
              console.log(e.target.value);
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].content = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>
        </div>
        <div className="buttonZAKL">
          <TextareaAutosize
            className="buttonZAKL"
            placeholder="-"
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].zakl
            }
            onChange={(e) => {
              console.log(e.target.value);
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].zakl = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>
        </div>
      </div>
    </>
  );
};
export default CheckboxRedactor;
