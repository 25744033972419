const mskArray = [
  "МРТ мягких тканей",
  "МРТ мягких тканей шеи",
  "МРТ височно-нижнечелюстного сустава",
  "МРТ кисти",
  "МРТ лучезапястного сустава",
  "МРТ локтевого сустава",
  "МРТ плечевого сустава",
  "МРТ костей таза",
  "МРТ тазобедренного сустава",
  "МРТ коленного сустава",
  "МРТ голеностопного сустава",
  "МРТ стопы",
];
const neuroArray = [
  "МРТ головного мозга",
  "МРТ орбит",
  "МРТ придаточных пазух носа",
  "МРТ мостозжечковых углов",
  "МР-перфузия головного мозга",
  "Бесконтрастная МР-ангиография интракраниальных артерий",
  "Бесконтрастная МР-ангиография интракраниальных вен и синусов",
  "Бесконтрастная МР-ангиография интракраниальных артерий, вен и синусов",
  "МРТ головного мозга, бесконтрастная МР-ангиография интракраниальных артерий, вен и синусов",
  "Бесконтрастная МР-ангиография сосудов шеи",
  "МРТ гипоталамо-гипофизарной области",
];
const abdArray = [
  "МРТ органов брюшной полости",
  "МРТ органов брюшной полости с гепатоспецифическим контрастным веществом",
  "Магнитно-резонансная холангиопанкреатография (МРХПГ)",
  "МРТ органов забрюшинного пространства",
  "МРТ почек и надпочечников",
];
const spinalArray = [
  "МРТ шейного отдела позвоночника",
  "МРТ грудного отдела позвоночника",
  "МРТ пояснично-крестцового отдела позвоночника",
  "МРТ крестцово-копчикового отдела позвоночника",
  "МРТ крестцово-позвдошных сочленений",
  "МРТ спинного мозга",
  "МРТ плечевого сплетения",
  "МРТ пояснично-крестцового сплетения",
];
const uroGinoArray = [
  "МРТ органов малого таза",
  "Мультипараметрическая МРТ предстательной железы",
  "МРТ прямой кишки",
  "МРТ органов мошонки",
  "МРТ молочных желёз",
];
const protocolsMRI = [
  ...neuroArray,
  ...mskArray,
  ...abdArray,
  ...uroGinoArray,
  ...spinalArray,
  "Другое",
];
module.exports = {
  protocolsMRI,
  mskArray,
  neuroArray,
  abdArray,
  uroGinoArray,
  spinalArray,
};
