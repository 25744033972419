import { useState } from "react";
const Img = ({ src }) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {
        <img
          src={src}
          alt={src}
          onLoad={() => setLoading(false)}
          style={{
            display: loading && "none",
          }}
        />
      }
      {loading && <div className="loaderSpinner"></div>}
    </>
  );
};
export default Img;
