import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import FishLogo from "./svg/FishLogo";

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const logoutClick = () => {
    logout();
  };

  return (
    <header
      style={{
        background: !user && "rgba(119, 159, 255, 0.03)",
      }}
    >
      <div className="container">
        <div className="rybovProdaete" style={{ display: "flex" }}>
          <div
            className="logoIMG"
            style={{
              position: "relative",
            }}
          >
            <FishLogo />
            {/* <img
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/c8cb694273ae7bb71f915345176aa46a-Photoroom.png"
              style={{
                position: "absolute",
                width: "140px",
                left: "-20px",
                top: "-36px",
                rotate: "205deg",
                transform: "rotateX(180deg)",
                scale: "0.4",
              }}
              alt="new year"
            /> */}
          </div>
          <div className="logoTXT">
            <Link to="/">
              <h1>
                <>
                  {/* <span className="spanTurbo">TurboRyba</span> */}
                  <span className="spanTurbo">Turboryba</span>
                  {/* <span className="spanRyba">ryba</span> */}
                </>
                <span
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "100",
                    position: "relative",
                    top: "-7px",
                    left: "1px",
                  }}
                >
                  Сегодня домой пораньше
                </span>
              </h1>
            </Link>
          </div>
        </div>
        {user && (
          <div className="navbarLinksContainer">
            <div className="navbarTop">
              <Link to="/">
                <p className="navbarLink">Мои шаблоны</p>
              </Link>

              <Link to="/sharedRybas">
                <p className="navbarLink">Импорт шаблонов</p>
              </Link>
              <Link to="/calculators">
                <p className="navbarLink">Калькуляторы</p>
              </Link>
              <Link to="/profilePage">
                <p className="navbarLink">Настройки</p>
              </Link>
              <Link to="/howtouse">
                <p className="navbarLink">Как пользоваться</p>
              </Link>
            </div>
            <div className="navbarBottom">
              {/* {" "}
            <Link to="/rybaListCT2">
              <p className="navbarLink">КТ</p>
            </Link>
            <Link to="/rybaListMRI2">
              <p className="navbarLink">МРТ</p>
            </Link> */}
            </div>
          </div>
        )}
        {!user && (
          <div className="navbarLinksContainer">
            <div className="navbarTop">
              {/* <Link to="/">
                <p className="navbarLink">Мои шаблоны</p>
              </Link>
              <Link to="/sharedRybas">
                <p className="navbarLink">Импорт шаблонов</p>
              </Link> */}
              {/* <Link to="/calculators">
                <p className="navbarLink">Калькуляторы</p>
              </Link> */}
              {/* <Link to="/profilePage">
                <p className="navbarLink">Настройки</p>
              </Link>
              <Link to="/howtouse">
                <p className="navbarLink">Как пользоваться</p>
              </Link> */}
            </div>
            <div className="navbarBottom">
              {/* {" "}
            <Link to="/rybaListCT2">
              <p className="navbarLink">КТ</p>
            </Link>
            <Link to="/rybaListMRI2">
              <p className="navbarLink">МРТ</p>
            </Link> */}
            </div>
          </div>
        )}
        {!user && (
          <>
            {" "}
            <div className="logout">
              <Link to="/login">
                <p className="loginButtonNavbar">Войти</p>
              </Link>
            </div>
            <div className="logout">
              <Link to="/signup">
                <button className="signupButton">Регистрация</button>
              </Link>
            </div>
          </>
        )}
        {user && (
          <div className="logout">
            <p style={{ color: "white", fontSize: "15px" }}>
              Добро пожаловать, {user.email}
            </p>
            <button className="logout" onClick={logoutClick}>
              Выйти
            </button>
          </div>
        )}
        {/* <Link to="/lala">
          <div className="skull">💀</div>
          <span className="profileText">Личный кабинет</span>
        </Link> */}
      </div>
    </header>
  );
};

export default Navbar;
