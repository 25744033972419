import { useEffect, useState, useRef } from "react";

import "./HowToUse.css";
import "./editing/NotificationWindow";

import React from "react";
import Article0 from "./howtouse/Article0";

const HowToUse = () => {
  const [articleOpen, setArticleOpen] = useState([true]);

  useEffect(() => {}, []);

  // счётчики сегментов и текстовых полей рыбы

  return (
    <div className="howCONTAINER">
      <div className="how">
        <div className="contents">
          <div
            className="content1"
            onClick={() => {
              let a = [];
              a[0] = true;
              setArticleOpen(a);
            }}
          >
            О приложении
          </div>{" "}
          <div
            className="content1"
            onClick={() => {
              let a = [];
              a[1] = true;
              setArticleOpen(a);
            }}
          >
            Помогите уже с названием
          </div>
        </div>
        <div className="articlesCONTAINER">
          {articleOpen[0] && <Article0 />}
          {articleOpen[1] && (
            <div className="article">
              <h1>Не получается придумать название</h1>
              <p>
                Только каждый третий знает, что рыба это ещё и шаблон, а надо,
                чтобы понятно было всем. Чесслово затрахался думать, ещё
                немного, и это превратится в шаблон точка ру
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowToUse;
