import Arrow from "./svg/Arrow";

const ArrowComponent = ({ top, left, rotate }) => {
  return (
    <div
      className="tutorialArrow"
      style={{
        top: top,
        left: left,
        rotate: rotate,
      }}
    >
      <Arrow height="48" width="48" />
    </div>
  );
};
export default ArrowComponent;
