import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";

const InputPrompt = ({
  promptText,
  setPromptInputValue,
  setInputPromptOpen,
  choicesCount,
  promptButton,
  setPromptText,
  setChoicesCount,
  text,
  setText,
  organINDEX,
  segmentINDEX,
  buttonINDEX,
  rybaObject,
  checkForDotsZakl,
  setInputPromptZaklOpen,
}) => {
  let p = 0;
  const [addingStarted, setAddingStarted] = useState(false);

  const setPromptInputValueCHOICES = (c) => {
    let q = [...promptText];
    const i = q[0] + c + q[2];
    if (i.includes("...")) {
      setChoicesCount((x) => (x += 1));
      setInputPromptOpen(true);
      let g = [];
      let y = i;
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }

    let t = [...text];
    ///////
    t[organINDEX.current][segmentINDEX.current] += rybaObject.organs[
      organINDEX.current
    ].segments[segmentINDEX.current].buttons[buttonINDEX.current].newline
      ? "\n" + i
      : i;
    t[organINDEX.current][segmentINDEX.current] = t[organINDEX.current][
      segmentINDEX.current
    ]
      .trim()
      .replaceAll("  ", " ")
      .replaceAll("   ", " ");

    setText(t);

    setInputPromptOpen(false);
    console.log("promptButton passed in prompt: ", promptButton);
    checkForDotsZakl(promptButton.zakl, promptButton);
  };

  // enter handler
  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPromptInputValue();
    }
    if (e.key === "Tab") {
      e.preventDefault();
      let f = [...promptText];
      f[1] = "";
      setPromptText(f);
      setPromptInputValue();
    }
    if (e.key === "Escape") {
      setInputPromptOpen(false);
    }
  };

  return (
    <div className="popupContainer" key={(p += 1)}>
      <div
        className="popupContent"
        style={{
          width: "450px",
        }}
        key={(p += 1)}
      >
        {Object.hasOwn(promptButton, "choices") &&
          choicesCount < promptButton.choices.length && (
            <div className="popupAnchor">
              <div className="popupInstructions">
                <p>ЛЕВАЯ КНОПКА - ввести и перейти дальше</p>
                <p>
                  ПРАВАЯ КНОПКА - добавить через запятую{" "}
                  <span
                    style={{
                      fontWeight: "900",
                    }}
                  >
                    несколько вариантов
                  </span>
                </p>
                <p>...или введите текст вручную</p>
              </div>
            </div>
          )}

        <div className="promptDiv" key={(p += 1)}>
          {promptText[0]}

          <span className="inputPromptSpan">{promptText[1]}</span>

          {promptText[2]}
        </div>

        <textarea
          key={promptText[0]}
          autoFocus
          className="inputPromptTextarea"
          value={promptText[1]}
          onChange={(e) => {
            let f = [...promptText];
            f[1] = e.target.value;
            setPromptText(f);
          }}
          onKeyDown={submitEnter}
        ></textarea>
        <div className="inputPromptOptions">
          {Object.hasOwn(promptButton, "choices") &&
            choicesCount < promptButton.choices.length &&
            promptButton.choices[choicesCount].map((c, index) => (
              <button
                onClick={() => {
                  if (addingStarted === true) {
                    let f = [...promptText];
                    f[1] += `, ${c}`;

                    console.log(f[1].slice(0, 2));
                    if (f[1].slice(0, 2) === ", ") {
                      f[1] = f[1].slice(2);
                    }
                    setPromptInputValueCHOICES(f[1]);
                    setAddingStarted(false);
                    return;
                  }
                  if (addingStarted === false) {
                    setPromptInputValueCHOICES(c);
                  }
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setAddingStarted(true);
                  let f = [...promptText];
                  f[1] += `, ${c}`;
                  console.log(f[1].slice(0, 2));
                  if (f[1].slice(0, 2) === ", ") {
                    f[1] = f[1].slice(2);
                  }
                  setPromptText(f);
                }}
              >
                {c}
              </button>
            ))}
        </div>

        <button className="saveChanges" onClick={setPromptInputValue}>
          Ввести (Enter)
        </button>
        <button
          className="saveChanges"
          onClick={() => {
            let f = [...promptText];
            f[1] = "";
            setPromptText(f);
            setPromptInputValue();
          }}
        >
          Пропустить (Tab)
        </button>
        <button
          className="closeTextEditWindow"
          onClick={() => {
            setInputPromptOpen(false);
          }}
        >
          Закрыть (Esc)
        </button>
      </div>
    </div>
  );
};
export default InputPrompt;
