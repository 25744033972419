import DeleteIcon from "../svg/DeleteIcon";
import ArrowUp from "../svg/ArrowUp";
import ArrowDown from "../svg/ArrowDown";
import Duplicate from "../svg/Duplicate";
import TextareaAutosize from "react-textarea-autosize";
import Dropdown from "../svg/Dropdown";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";
import ConstructorSVG from "../svg/ConstructorSVG";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import RadioRedactor from "./buttonsRedactorVariants/RadioRedactor";
import CheckboxRedactor from "./buttonsRedactorVariants/CheckboxRedactor";
import ConstructorRedactor from "./buttonsRedactorVariants/ConstructorRedactor";
import SelectRedactor from "./buttonsRedactorVariants/SelectRedactor";
import Img from "./Img";
import { useAuthContext } from "../../hooks/useAuthContext";
import ChoicesConstructor from "./buttonsRedactorVariants/ChoicesConstructor";
import ChoicesConstructorZakl from "./buttonsRedactorVariants/ChoicesConstructorZakl";

const ButtonsRedactor = ({
  segment,
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  deleteButtonRequest,
  locateButton,
  duplicateButton,
  moveButtonUpward,
  moveButtonDownward,
  notification,
  contextMenuButton,
  buttonContextMenu,

  contextHighlight,
  setContextHighlight,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { user } = useAuthContext();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const constructorsArray = [
    "Кисты почек Bosniak I",
    "Объём печени",
    "Спленомегалия",
    "Гидроторакс",
    "Объём простаты",
    "Объём яичника",
    "Объём яичка",
    "Индекс вымывания (надпочечники)",
    // "ASPECTS",
    "Калькулятор стеноза артерии",
    "Стеноз ВСА по ECST и NASCET",
    "Лимфаденопатия ОГК",
    "Очаговые изменения лёгких",
    "Инфильтративные изменения лёгких",
    // "Фиброзные изменения лёгких",
    // "Очаговые изменения печени",
  ];

  const uploadImageToButton = async (e, image) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    console.log(formData);
    const response = await fetch(`https://api.defaultuploader.com/v1/upload`, {
      method: "POST",
      headers: {
        Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
      },
      body: formData,
    });

    console.log(response);
    const json = await response.json();
    if (response.ok) {
      json[0].author = user.id;
      console.log(json);
      console.log(response);
      let r = { ...rybaObject };
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].images.push(json[0]);
      setRybaObject(r);
      setLoading(false);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  const deleteImageToButton = async (e, pathOriginal, img, index) => {
    if (user.id !== img.author) {
      let r = { ...rybaObject };
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].images.splice(index, 1);
      setRybaObject(r);
      setDeleting(false);
    }
    const response = await fetch(
      `https://api.defaultuploader.com/v1/upload/${pathOriginal}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
        },
      }
    );

    const json = await response;
    if (response.ok) {
      console.log("успешно удалено, джесон: ", json);
      console.log("успешно удалено, сам респонс: ", response);
      let r = { ...rybaObject };
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].images.splice(index, 1);
      setRybaObject(r);
      setDeleting(false);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  const setChoices = (
    buttonType,
    choicesObject,
    organIndex,
    segmentIndex,
    buttonIndex,
    radioIndex,
    optionIndex
  ) => {
    let r = { ...rybaObject };
    if (buttonType === "checkbox") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].choices =
        choicesObject;
    }
    if (buttonType === "radio") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].radios[
        radioIndex
      ].choices = choicesObject;
    }
    if (buttonType === "select") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].options[
        optionIndex
      ].choices = choicesObject;
    }
    setRybaObject(r);
  };

  const setChoicesZakl = (
    buttonType,
    choicesObject,
    organIndex,
    segmentIndex,
    buttonIndex,
    radioIndex,
    optionIndex
  ) => {
    let r = { ...rybaObject };
    if (buttonType === "checkbox") {
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].choicesZakl = choicesObject;
    }
    if (buttonType === "radio") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].radios[
        radioIndex
      ].choicesZakl = choicesObject;
    }
    if (buttonType === "select") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].options[
        optionIndex
      ].choicesZakl = choicesObject;
    }
    setRybaObject(r);
  };

  return (
    <div
      className="buttonAndContent"
      onContextMenu={(e) => {
        contextMenuButton(e, organIndex, segmentIndex, buttonIndex);
        setContextHighlight(buttonIndex);
      }}
      style={{
        opacity: buttonContextMenu && contextHighlight === buttonIndex && "0.5",
      }}
    >
      <div className="editDiv2">
        <div>
          <button
            className="svgButton"
            onClick={(e) => {
              deleteButtonRequest(e, buttonIndex, segmentIndex, organIndex);
            }}
          >
            <DeleteIcon width={24} height={24} />
          </button>
          <button
            className="svgButton"
            onClick={() => {
              locateButton(organIndex, segmentIndex, buttonIndex);
              duplicateButton();
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              notification("Кнопка скопирована");
              let r = { ...rybaObject };
              window.localStorage.setItem(
                `copiedBUTTON`,
                JSON.stringify(
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ]
                )
              );
            }}
          >
            <Duplicate
              width="20px"
              height="20px"
              style={{ pointerEvents: "none" }}
            />
          </button>
        </div>
        <div>
          {buttonIndex > 0 && (
            <button
              className="svgButton"
              onClick={() => {
                moveButtonUpward(organIndex, segmentIndex, buttonIndex);
              }}
            >
              <ArrowUp />
            </button>
          )}
          {buttonIndex < segment.buttons.length - 1 && (
            <button
              className="svgButton"
              onClick={() => {
                moveButtonDownward(organIndex, segmentIndex, buttonIndex);
              }}
            >
              <ArrowDown />
            </button>
          )}
        </div>
        <div className="buttonItself" index={buttonIndex}>
          {button.type === "radio" && (
            <RadioSVG width={48} height={48} color={"white"} />
          )}
          {button.type === "checkbox" && (
            <CheckboxsVG width={48} height={48} color={"white"} />
          )}
          {button.type === "select" && (
            <Dropdown width={48} height={48} color={"white"} />
          )}
          {button.type === "constructor" && (
            <ConstructorSVG width={32} height={32} color={"white"} />
          )}
        </div>
      </div>
      <div className="buttonContentDiv">
        <div className="buttonTITLEradioORselect">
          <TextareaAutosize
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].title
            }
            onChange={(e) => {
              if (button.type === "constructor") {
                return;
              }
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].title = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>
          <label>
            Добавлять текст с новой строки
            <input
              type="checkbox"
              checked={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].newline
              }
              onChange={(e) => {
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].newline = e.target.checked;
                setRybaObject(r);
              }}
            />
          </label>
        </div>
        {button.type === "checkbox" && (
          <CheckboxRedactor
            {...{
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
            }}
          />
        )}{" "}
        {button.type === "radio" && (
          <RadioRedactor
            {...{
              button,
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              notification,
              setChoices,
              setChoicesZakl,
            }}
          />
        )}{" "}
        {button.type === "select" && (
          <SelectRedactor
            {...{
              button,
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              notification,
              setChoices,
              setChoicesZakl,
            }}
          />
        )}{" "}
        {button.type === "constructor" && (
          <ConstructorRedactor
            {...{
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              constructorsArray,
            }}
          />
        )}
        {/* {Object.hasOwn(button, "choices") && button.type === "checkbox" && (
          <div className="choices">
            <div
              style={{
                marginBottom: "10px",
                marginTop: "5px",
              }}
            >
              Опции для пробелов ("...")
              {!optionsOpen && (
                <span
                  className="choicesButton"
                  onClick={() => {
                    setOptionsOpen(true);
                  }}
                >
                  Показать опции
                </span>
              )}
              {optionsOpen && (
                <span
                  className="choicesButton"
                  onClick={() => {
                    setOptionsOpen(false);
                  }}
                >
                  Свернуть опции
                </span>
              )}
            </div>
            {optionsOpen && (
              <>
                <div
                  className="optionsTextPreview"
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  {button.content.split("...").map((chunk, index) => (
                    <span>
                      {chunk}
                      <span
                        className="numberOfOption"
                        style={{
                          display:
                            index + 1 === button.content.split("...").length &&
                            "none",
                        }}
                      >
                        {index < button.content.split("...").length - 1 &&
                          index + 1}
                      </span>
                    </span>
                  ))}
                </div>
                {button.choices.map((c, i) => (
                  <div className="choicesSegmentContainer">
                    <div className="choicesSegmentNumber">{i + 1}</div>
                    <div className="choicesSegment">
                      {button.choices[i].map((c, index) => (
                        <>
                          <span
                            className="choicesOption"
                            value={c}
                            onChange={(e) => {
                              let r = { ...rybaObject };
                              r.organs[organIndex].segments[
                                segmentIndex
                              ].buttons[buttonIndex].choices[i][index] =
                                e.target.value;
                              setRybaObject(r);
                            }}
                          >
                            {c}
                          </span>
                          <span
                            className="choiceDeleteSpan add"
                            onClick={() => {
                              console.log("click has been");
                              let r = { ...rybaObject };
                              r.organs[organIndex].segments[
                                segmentIndex
                              ].buttons[buttonIndex].choices[i].splice(
                                index,
                                1
                              );
                              setRybaObject(r);
                            }}
                          >
                            <DeleteIcon width={24} height={24} />
                          </span>
                        </>
                      ))}
                      {!addingOption && (
                        <span
                          className="choicesButton add"
                          onClick={() => {
                            setAddingOption(true);
                            setWhichOption(i);
                          }}
                        >
                          Добавить опцию
                        </span>
                      )}

                      {i === whichOption && addingOption && (
                        <div>
                          <TextareaAutosize
                            autoFocus
                            className="choicesTextarea"
                            value={optionText}
                            onChange={(e) => {
                              setOptionText(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                let r = { ...rybaObject };
                                r.organs[organIndex].segments[
                                  segmentIndex
                                ].buttons[buttonIndex].choices[i].push(
                                  optionText
                                );
                                setRybaObject(r);
                                setAddingOption(false);
                                setOptionText("");
                              }
                            }}
                          >
                            {optionText}
                          </TextareaAutosize>
                          <div
                            className="choicesButton"
                            onClick={() => {
                              let r = { ...rybaObject };
                              r.organs[organIndex].segments[
                                segmentIndex
                              ].buttons[buttonIndex].choices[i].push(
                                optionText
                              );
                              setRybaObject(r);
                              setAddingOption(false);
                              setOptionText("");
                            }}
                          >
                            OK
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="choicesDelete"
                      onClick={() => {
                        let r = { ...rybaObject };
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].choices.splice(i, 1);
                        setRybaObject(r);
                      }}
                    >
                      <DeleteIcon width={24} height={24} />
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    margin: "10px",
                  }}
                >
                  <span
                    className="choicesButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].choices.push([]);
                      setRybaObject(r);
                    }}
                  >
                    Добавить группу опций
                  </span>
                </div>
              </>
            )}
          </div>
        )}   */}
        {Object.hasOwn(button, "choices") && button.type === "checkbox" && (
          <ChoicesConstructor
            buttonItself={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ]
            }
            setChoices={setChoices}
            buttonType={"checkbox"}
            organIndex={organIndex}
            segmentIndex={segmentIndex}
            buttonIndex={buttonIndex}
            radioIndex={undefined}
            optionIndex={undefined}
          />
        )}
        {Object.hasOwn(button, "choicesZakl") && button.type === "checkbox" && (
          <ChoicesConstructorZakl
            buttonItself={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ]
            }
            setChoicesZakl={setChoicesZakl}
            buttonType={"checkbox"}
            organIndex={organIndex}
            segmentIndex={segmentIndex}
            buttonIndex={buttonIndex}
            radioIndex={undefined}
            optionIndex={undefined}
          />
        )}
        {Object.hasOwn(button, "images") && (
          <div className="choices">
            <div
              style={{
                marginBottom: "5px",
              }}
            >
              Картинки, привязанные к кнопке:
            </div>
            <div className="imagesContainter">
              {button.images.map((image, index) => (
                <div className="choicesSegmentContainer">
                  {deleting && <div className="loaderSpinner"></div>}
                  {!loadingDelete && (
                    <div
                      className="buttonImageContainer"
                      style={{
                        display: deleting && "none",
                      }}
                    >
                      <div
                        className="buttonImageDelete"
                        onClick={(e) => {
                          setDeleting(true);
                          deleteImageToButton(
                            e,
                            image.s3PathOriginal,
                            image,
                            index
                          );
                        }}
                      >
                        <DeleteIcon width={24} height={24} />
                      </div>
                      <Img src={image.src} />
                    </div>
                  )}
                  {loadingDelete && <div className="loaderSpinner"></div>}
                </div>
              ))}
            </div>
            <div>
              <div className="imagesUploadDiv">
                Добавить картинку (можно перетащить)
                {!loading && (
                  <input
                    className="imageInput"
                    accept="image/png, image/jpeg"
                    type="file"
                    name="file"
                    onChange={(e) => {
                      uploadImageToButton(e, e.target.files[0]);
                    }}
                  />
                )}
                {loading && <div className="loaderSpinner"></div>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonsRedactor;
