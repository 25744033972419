import { useEffect, useState, useRef } from "react";
import img1 from "./gifs/img1.png";
import { useNavigate } from "react-router-dom";

import "./LandingPage.css";
import "./editing/NotificationWindow";

import React from "react";

const LandingPage = () => {
  const [articleOpen, setArticleOpen] = useState([true]);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  // счётчики сегментов и текстовых полей рыбы

  return (
    <div className="landingContainer">
      {/* <div className="landingSegment">
        <h1 className="landingh1">
          Турборыба
          <span className="landingspan1"> за секунды</span>
        </h1>
      </div> */}

      <div className="landingSegment">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "50px",
            height: "90vh",
          }}
        >
          <div
            className="landingText"
            style={{
              width: "550px",
            }}
          >
            <p
              style={{
                marginTop: "140px",
              }}
            >
              Приложение для врачей лучевой диагностики, создающее описание
              <span className="landingspan1"> за пару кликов</span>.
            </p>
            <button
              className="tryRybaButton"
              onClick={() => {
                navigate("/tutorial");
              }}
            >
              Нажмите для демонстрации работы
            </button>
            <ul
              style={{
                marginTop: "70px",
                marginLeft: "80px",
                fontSize: "24px",
              }}
            >
              <li
                style={{
                  marginBottom: "20px",
                }}
              >
                Формулировка в описание и заключение за один клик
              </li>
              <li
                style={{
                  marginBottom: "20px",
                }}
              >
                Встроенные калькуляторы объёмов и индексов
              </li>
              {/* <li
                style={{
                  marginBottom: "20px",
                }}
              >
                Все элементы шаблонов можно настроить под себя
              </li> */}
              <li
                style={{
                  marginBottom: "20px",
                }}
              >
                Огромный набор готовых шаблонов на все случаи жизни с готовыми
                формулировками,
                <span className="landingspan1">
                  {" "}
                  не нужно изобретать велосипед
                </span>
              </li>
              <li
                style={{
                  marginBottom: "20px",
                }}
              >
                Не нравятся чужие формулировки? Можно
                <span className="landingspan1"> настроить все под себя</span>
              </li>
              <li
                style={{
                  marginBottom: "20px",
                }}
              >
                Доступ через браузер в любом месте,
                <span className="landingspan1">
                  {" "}
                  не нужно ничего устанавливать
                </span>
              </li>
            </ul>
            {/* <p
              style={{
                marginTop: "70px",
                fontSize: "26px",
                marginLeft: "16px",
              }}
            >
              От слов к делу:
            </p> */}

            {/* <p
              style={{
                marginTop: "70px",
                fontSize: "26px",
                marginLeft: "16px",
              }}
            >
              ...ну или ниже поподробнее ⬇️
            </p> */}

            {/* <p>
              Создавайте описания{" "}
              <span className="landingspan1"> за секунды</span> и освобождайте
              время для сложных случаев.
            </p>
            <p>
              Не тратьте драгоценное время, чтобы печатать одно и то же в сотый
              раз -
              <span className="landingspan1"> просто нажмите на кнопку</span>.
            </p>
            <p>
              <span className="landingspan1">
                {" "}
                Можно не изобретать велосипед
              </span>{" "}
              - используйте готовые шаблоны и формулировки, созданные опытными
              рентгенологами.
            </p> */}
          </div>
          <div
            style={{
              position: "relative",
              left: "100px",
              width: "500px",
              height: "600px",
              scale: "1",
            }}
          >
            <div
              style={{
                width: "900px",
                height: "900px",
                position: "absolute",
                zIndex: "10",
                background: "rgb(255,255,255)",
                background:
                  "radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(10,10,10,1) 93%, rgba(0,0,0,1) 100%)",
              }}
            ></div>
            <img
              className="landingimg"
              style={{
                width: "600px",
                zIndex: "1",
              }}
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202024-12-31%20%D0%B2%2013.31.59.png"
              alt="шаблон рентген кт мрт описание заключение для рентгенологов"
            />
            <img
              className="landingimg"
              style={{
                width: "450px",
                zIndex: "2",
                left: "400px",
                top: "340px",
              }}
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202024-12-31%20%D0%B2%2012.31.21.png"
              alt="шаблон рентген кт мрт описание заключение для рентгенологов"
            />
            <img
              className="landingimg"
              style={{
                width: "300px",
                zIndex: "1",
                left: "120px",
                top: "480px",
              }}
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202024-12-31%20%D0%B2%2015.57.39.png"
              alt="шаблон рентген кт мрт описание заключение для рентгенологов"
            />
          </div>
        </div>
      </div>

      {/* <h1
        className="landingh1"
        style={{
          marginTop: "200px",
        }}
      >
        Добавляй формулировки
        <span className="landingspan1"> в один клик</span>
      </h1>

      <div className="landingSegment">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "50px",
          }}
        >
          <div>
            <img
              style={{
                width: "1100px",
                borderRadius: "12px",
              }}
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/Dec-31-2024%2020-39-15.gif"
              alt="knopka"
            />
          </div>
        </div>
      </div>
      <p className="imageCommentLanding">
        Можно добавлять текст сразу
        <span className="landingspan1"> и в описание, и в заключение</span>.
      </p>
      <h1 className="landingh1">
        Добавляй формулировки
        <span className="landingspan1"> в один клик</span>
      </h1> */}
    </div>
  );
};

export default LandingPage;
