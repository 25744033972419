import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import ICAstenosisIMG from "../../images/ICAstenosisIMG.png";

const ICAstenosis = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [size3, setSize3] = useState(null);
  const [side, setSide] = useState(null);
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (!side) return alert("Укажите сторону");
    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let ECST = ((yy - xx) / yy) * 100;
    ECST = Math.round(ECST);
    let NASCET = ((zz - xx) / zz) * 100;
    NASCET = Math.round(NASCET);

    let finalMeasure = "";

    finalMeasure = `Общий диаметр С1 сегмента ${
      side === "R" ? "правой" : "левой"
    } ВСА на уровне стеноза составляет ${yy} мм, выше уровня стеноза ${zz} мм, диаметр контрастируемого просвета на уровне стеноза ${xx} мм; расчётный процент стеноза по ECST составляет ${ECST}%, по NASCET ${NASCET}%.`;

    zaklStringFinal = `Стеноз С1 сегмента ${
      side === "R" ? "правой" : "левой"
    } ВСА (${ECST}% по ESCT, ${NASCET}% по NASCET). `;

    console.log(finalMeasure);
    ///////
    ///////
    if (dinamika) {
      dinamikaConstructorFunction(finalMeasure, zaklStringFinal);
      setConstructorOpen(false);
      return;
    }
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }
    if (zaklStringFinal !== "") {
      t[rybaLength][0] += zaklStringFinal;
    }
    setText(t);
    setConstructorOpen(false);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h2
        key={(p += 1)}
        style={{
          margin: "0px",
        }}
      >
        Расчёт стеноза С1 ВСА по ECST и NASCET
      </h2>

      <img
        src={ICAstenosisIMG}
        alt="spleen"
        style={{
          width: "300px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      />
      <div>
        <fieldset
          style={{
            display: "flex",
            borderRadius: "6px",
          }}
        >
          <legend>Сторона</legend>
          <label>
            <input
              type="radio"
              name="side"
              onClick={(e) => {
                setSide("R");
              }}
            />
            СПРАВА
          </label>
          <label>
            <input
              type="radio"
              name="side"
              onClick={(e) => {
                setSide("L");
              }}
            />
            СЛЕВА
          </label>
        </fieldset>
      </div>

      <div
        className="inputVertical"
        style={{
          display: "flex",
        }}
      >
        <label>
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            autoFocus
            type="number"
            placeholder="А в мм"
            value={size1}
            ы
            onChange={(e) => setSize1(e.target.value)}
            onKeyDown={enter}
          />
          {"  "}Диаметр ПРОСВЕТА на уровне стеноза
        </label>
        <label>
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="В в мм"
            value={size2}
            onChange={(e) => setSize2(e.target.value)}
            onKeyDown={enter}
          />
          {"  "}Диаметр ПРОСВЕТА ВЫШЕ уровня стеноза
        </label>
        <label>
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="С в мм"
            value={size3}
            onChange={(e) => setSize3(e.target.value)}
            onKeyDown={enter}
          />
          {"  "} ОБЩИЙ диаметр на уровне стеноза
        </label>
      </div>

      <button
        className="calculate"
        onClick={() => {
          // calculate();
          textHandler();
        }}
      >
        Рассчитать
      </button>
      {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      {!side && <p style={{ color: "red" }}>СТОРОНА НЕ ВЫБРАНА</p>}
      <button
        className="calculate"
        onClick={() => {
          // calculate();
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default ICAstenosis;
