import { Link } from "react-router-dom";
import { useState } from "react";
import Img from "./Img";
const ImageComponent = ({ shpora, shporaIndex }) => {
  const [opened, setOpened] = useState(false);

  if (opened) {
    return (
      <div
        className="imageOneSegment"
        style={{
          position: "relative",
        }}
        onClick={() => {
          setOpened(false);
        }}
      >
        <p>{shpora.title}</p>
        <div
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {shpora.text}
        </div>
        <div>
          {shpora.images.map((image, imageIndex) => (
            <Img src={image.originalSrc} />
          ))}
        </div>
        <div
          style={{
            position: "absolute",
            right: "4px",
            top: "4px",
            rotate: "90deg",
          }}
        >
          {">"}
        </div>
      </div>
    );
  }

  if (!opened) {
    return (
      <div
        className="imageOneSegment"
        style={{
          position: "relative",
        }}
        onClick={() => {
          setOpened(true);
        }}
      >
        {shpora.title}
        <div
          style={{
            position: "absolute",
            right: "4px",
            top: "4px",
          }}
        >
          {">"}
        </div>
      </div>
    );
  }
};
export default ImageComponent;
