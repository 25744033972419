import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { useAuthContext } from "./hooks/useAuthContext";
import { useEffect } from "react";
// pages & components
import { SharedRybas } from "./pages/SharedRybas";
import Navbar from "./components/Navbar";
import RybaList from "./components/RybaList";
import RybaListXRAY from "./components/RybaListXRAY";
import RybaListUS from "./components/RybaListUS";
import RybaListMAMMOGRAPHY from "./components/RybaListMAMMOGRAPHY";
import RybaListPET from "./components/RybaListPET";
import RybaRedactor2 from "./components/RybaRedactor2";

import Ryba4 from "./components/Ryba4";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Calculators from "./components/Сalculators";
import RybaListCT2 from "./components/RybaListCT2";
import RybaListMRI2 from "./components/RybaListMRI2";
import ProfilePage from "./components/ProfilePage";
import RybaPDF from "./components/RybaPDF";
import HowToUse from "./components/HowToUse";
import NewPassword from "./pages/NewPassword";
import RybaDinamica from "./components/RybaDinamica";
import LandingPage from "./components/LandingPage";
import RybaTutorial from "./components/Ryba5tutorial";

function App() {
  const { user } = useAuthContext();
  useEffect(() => {
    if (user) {
    }
  }, [user]);
  return (
    <div className="App">
      <BrowserRouter>
        <RecoilRoot>
          <Navbar />
          <div className="pages">
            <Routes>
              <Route
                path="/"
                element={user ? <RybaList /> : <Navigate to="/landingpage" />}
              />
              <Route
                path="/landingpage"
                element={!user ? <LandingPage /> : <Navigate to="/" />}
              />
              <Route path="/tutorial" element={<RybaTutorial />} />
              <Route
                path="/rybaListCT2"
                element={user ? <RybaListCT2 /> : <Navigate to="/" />}
              />
              <Route
                path="/howtouse"
                element={user ? <HowToUse /> : <Navigate to="/" />}
              />
              <Route
                path="/profilePage"
                element={user ? <ProfilePage /> : <Navigate to="/" />}
              />
              <Route
                path="/rybaListMRI2"
                element={user ? <RybaListMRI2 /> : <Navigate to="/" />}
              />
              <Route
                path="/rybaListXRAY"
                element={user ? <RybaListXRAY /> : <Navigate to="/" />}
              />
              <Route
                path="/rybaListUS"
                element={user ? <RybaListUS /> : <Navigate to="/" />}
              />
              <Route
                path="/rybaListMAMMOGRAPHY"
                element={user ? <RybaListMAMMOGRAPHY /> : <Navigate to="/" />}
              />
              <Route
                path="/rybaListPET"
                element={user ? <RybaListPET /> : <Navigate to="/" />}
              />

              <Route
                path="/primary/:id"
                element={user ? <Ryba4 /> : <Navigate to="/login" />}
              />
              <Route
                path="/followup/:id"
                element={user ? <RybaDinamica /> : <Navigate to="/login" />}
              />

              <Route
                path="/sharedRybas"
                element={user ? <SharedRybas /> : <Navigate to="/login" />}
              />
              <Route
                path="/editRyba2/:id"
                element={user ? <RybaRedactor2 /> : <Navigate to="/login" />}
              />
              <Route path="/calculators" element={<Calculators />} />
              <Route path="/exportPDF" element={<RybaPDF />} />
              <Route
                path="/login"
                element={!user ? <Login /> : <Navigate to="/" />}
              />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/user/resetPassword/:token"
                element={<NewPassword />}
              />
            </Routes>
          </div>
        </RecoilRoot>
      </BrowserRouter>
    </div>
  );
}

export default App;
