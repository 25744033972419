const rybaForTutorial = {
  _id: "6710ff4f05e62f21c899dcc7",
  rank: "ryba",
  title: "ОГК",
  author: "67093dbd69080216404eb5e8",
  authorNickname: "StrawMD",
  modality: "КТ",
  protocol: "КТ органов грудной клетки",
  ratings: [10, 1, 10, 10, 10, 10],
  savedVersion: [],
  shared: true,
  numberTimesShared: 11,
  isChild: false,
  organs: [
    {
      title: "ШАПКА",
      shapka: true,
      segments: [
        {
          title: "Исследование",
          norm: "Исследование выполнено с толщиной слоя 1 мм нативно. ",
          buttons: [
            {
              type: "select",
              title: "Исследование",
              newline: false,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Натив 1 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1 мм нативно. ",
                  zakl: "",
                  checked: false,
                },
                {
                  title: "Натив 1.25 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1.25 мм нативно. ",
                },
                {
                  title: "Натив 2 мм",
                  content:
                    "Исследование выполнено с толщиной среза 2 мм нативно. ",
                },
                {
                  title: "Йогексол 1 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1 мм нативно и после введения Йогексола в стандартной дозировке. ",
                },
                {
                  title: "Йогексол 1.25 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1.25 мм нативно и после введения Йогексола в стандартной дозировке. ",
                },
                {
                  title: "Йогексол 2 мм",
                  content:
                    "Исследование выполнено с толщиной среза 2 мм нативно и после введения Йогексола в стандартной дозировке. ",
                },
                {
                  title: "Ниоскан 1 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1 мм нативно и после введения Ниоскана в стандартной дозировке. ",
                },
                {
                  title: "Ниоскан 1.25 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1.25 мм нативно и после введения Ниоскана в стандартной дозировке. ",
                },
                {
                  title: "Ниоскан 2 мм",
                  content:
                    "Исследование выполнено с толщиной среза 2 мм нативно и после введения Ниоскана в стандартной дозировке. ",
                },
                {
                  title: "Омнипак 1 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1 мм нативно и после введения Омнипака в стандартной дозировке. ",
                },
                {
                  title: "Омнипак 1.25 мм",
                  content:
                    "Исследование выполнено с толщиной среза 1.25 мм нативно и после введения Омнипака в стандартной дозировке. ",
                },
                {
                  title: "Омнипак 2 мм",
                  content:
                    "Исследование выполнено с толщиной среза 2 мм нативно и после введения Омнипака в стандартной дозировке. ",
                },
              ],
            },
          ],
        },
        {
          title: "Артефакты",
          norm: "Значимых артефактов не выявлено. ",
          buttons: [
            {
              title: "Двигательные артефакты",
              type: "checkbox",
              zakl: "",
              newline: false,
              content:
                "Интерпретация изображений затруднена за счёт двигательных артефактов. ",
            },
          ],
        },
      ],
    },
    {
      title: "КЛИНИЧЕСКИЕ ДАННЫЕ",
      shapka: false,
      segments: [
        {
          title: "Анамнез",
          norm: "",
          buttons: [
            {
              title: "Анамнез неизвестен",
              type: "checkbox",
              zakl: "",
              newline: false,
              content: "Анамнез неизвестен. ",
            },
          ],
        },
      ],
    },
    {
      title: "ЛЁГКИЕ",
      segments: [
        {
          title: "Ткань лёгких",
          norm: "очаговых, инфильтративных изменений не отмечено. ",
          buttons: [
            {
              title: "Очаговых и инфильтративных нет ",
              content:
                "Очаговых и инфильтративных изменений лёгких не отмечено. ",
              zakl: "Очаговых и инфильтративных изменений лёгких не отмечено. ",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Неполный вдох ",
              content:
                "Снижение воздушности лёгочной ткани на фоне неполного вдоха. ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Дыхательные артефакты",
              content:
                "Интерпретация изображений в условиях дыхательных артефактов. ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
            {
              type: "radio",
              title: "Очаги, кисты, буллы",
              newline: true,
              radios: [
                {
                  title: "Солидные очаги",
                  content: "В ... ... размерами до ... мм. ",
                  zakl: "... .... ",
                  choices: [
                    ["правом лёгком", "левом лёгком", "обоих лёгких"],
                    [
                      "единичный солидный очаг",
                      "единичные солидные очаги",
                      "множественные солидные очаги",
                    ],
                    [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "15",
                      "20",
                      "25",
                      "30",
                    ],
                  ],
                  choicesZakl: [
                    ["Солидный очаг", "Солидные очаги"],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Субсолидные очаги",
                  content: "В ... ... ... размерами до ... мм. ",
                  zakl: "... .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "единичный субсолидный очаг",
                      "единичные субсолидные очаги",
                      "множественные субсолидные очаги",
                    ],
                    [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "15",
                      "20",
                      "25",
                      "30",
                    ],
                  ],
                  choicesZakl: [
                    ["Субсолидный очаг", "Субсолидные очаги"],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Очаги матового стекла",
                  content:
                    'В ... ... ... уплотнения по типу "матового стекла" размерами до ... мм. ',
                  zakl: "... .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "единичный очаг ",
                      "единичные очаги ",
                      "множественные очаги",
                    ],
                    [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "15",
                      "20",
                      "25",
                      "30",
                    ],
                  ],
                  choicesZakl: [
                    [
                      'Очаг уплотнения по типу "матового стекла"',
                      'Очаги уплотнения по типу "матового стекла"',
                    ],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Буллы",
                  content: "В ... ... ... размерами до ... мм. ",
                  zakl: "... .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "единичная булла",
                      "единичные буллы",
                      "множественные буллы",
                    ],
                    [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "15",
                      "20",
                      "25",
                      "30",
                    ],
                  ],
                  choicesZakl: [
                    ["Единичная булла", "Буллы"],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Кисты",
                  content: "В ... ... ... размерами до ... мм. ",
                  zakl: "... .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "единичная киста",
                      "единичные кисты",
                      "множественные кисты",
                    ],
                    [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "15",
                      "20",
                      "25",
                      "30",
                    ],
                  ],
                  choicesZakl: [
                    ["Единичная киста", "Кисты"],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Бронхоцеле",
                  content:
                    "В ... ... ... неправильной формы, с чёткими, неровными контурами, ветвистой структуры (вероятнее, бронхоцеле), размерами до ... мм. ",
                  zakl: "Бронхоцеле .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "уплотнение",
                      "единичные уплотнения",
                      "множественные уплотнения",
                    ],
                    ["5", "6", "7", "8", "9", "10", "15", "20", "25", "30"],
                  ],
                  choicesZakl: [
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
              ],
              choices: [[]],
              choicesZakl: [[]],
            },
            {
              type: "radio",
              title: "Инфильтративные изменения, эмфизема",
              newline: true,
              radios: [
                {
                  title: "Бронхиолит",
                  content:
                    'В ... ... центрилобулярные ветвистые уплотнения лёгочной ткани по типу "дерева в почках" (наиболее характерны для бронхиолита). ',
                  zakl: "Бронхиолит .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                  ],
                  choicesZakl: [
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Консолидация (пневмония)",
                  content:
                    "В ... ... участки консолидации лёгочной ткани с дифференцирующимся просветом бронхов в составе. ",
                  zakl: "... .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                  ],
                  choicesZakl: [
                    [
                      "Пневмония",
                      "Полисегментарная пневмония",
                      "Аспирационная пневмония",
                    ],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Эмфизема",
                  content: "В ... ... участки ... эмфиземы. ",
                  zakl: "... эмфизема .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    ["центрилобулярной", "парасептальной", "панацинарной"],
                  ],
                  choicesZakl: [
                    ["Центрилобулярная", "Парасептальная", "Панацинарная"],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
              ],
              choices: [[]],
              choicesZakl: [[]],
            },
            {
              type: "radio",
              title: "Фиброзные изменения",
              newline: true,
              radios: [
                {
                  title: "Обычные",
                  content: "В ... ... отмечаются ..., .... ",
                  zakl: "... фиброзные изменения .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "плевропаренхимальные тяжи",
                      "субплевральные линии",
                      "ретикулярные уплотнения",
                      'уплотнения по типу "матового стекла"',
                    ],
                    [
                      "изменения выражены незначительно",
                      "изменения выражены слабо и занимают небольшую площадь лёгочной ткани",
                      "изменения выражены умеренно и преимущественно дифференцируются в периферических отделах лёгких",
                      "изменения значительно выражены, приводят к искажению физиологичной архитектоники лёгочной ткани",
                    ],
                  ],
                  choicesZakl: [
                    [
                      "Незначительные ",
                      "Слабовыраженные",
                      "Умеренно выраженные",
                      "Выраженные",
                    ],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Тракционные бронхоэктазы",
                  content: "В ... ... .... ",
                  zakl: "... фиброзные изменения .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    [
                      "единичный тракционный бронхоэктаз",
                      "единичные тракционные бронхоэктазы",
                      "множественные тракционные бронхоэктазы",
                    ],
                  ],
                  choicesZakl: [
                    [
                      "Незначительные ",
                      "Слабовыраженные",
                      "Умеренно выраженные",
                      "Выраженные",
                    ],
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
              ],
              choices: [[]],
              choicesZakl: [[]],
            },
            {
              type: "select",
              title: "Интерстициальные пневмонии",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Выраженный",
                  content:
                    "Значительно развитый пневмофиброз с нарушением физиологичной архитектоники лёгочной ткани, массивными ретикулярными уплотнениями, плевропаренхимальными тяжами и тракционными бронхоэктазами; паттерна UIP или NSIP не отмечено. ",
                  zakl: "Выраженный пневмофиброз (неспецифический). ",
                },
                {
                  title: "NSIP",
                  content:
                    "Отмечаются фиброзные изменения обоих лёгких, формирующие паттерн неспецифической интерстициальной пневмонии (NSIP): обширные зоны уплотнений по типу матового стекла с относительным щажением субплевральных отделов лёгочной ткани, преобладающие над ретикулярными уплотнениями, тракционными бронхоэктазами, слабовыраженной периферической ячеистой трансформацией по типу медовых сот. ",
                  zakl: "Пневмофиброз с формированием паттерна неспецифической интерстициальной пневмонии (NSIP). ",
                },
                {
                  title: "UIP",
                  content:
                    "Отмечаются фиброзные изменения обоих лёгких, формирующие паттерн обычной интерстициальной пневмонии (UIP): развитая (>5% площади лёгочной ткани) периферическая ячеистая трансформация лёгочной ткани, преобладающая над уплотнениями по типу матового стекла, ретикулярные уплотнения, тракционные бронхоэктазы. ",
                  zakl: "Пневмофиброз с формированием паттерна обычной интерстициальной пневмонии (UIP). ",
                },
              ],
            },
            {
              type: "select",
              title: "Операции",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                  choices: [[]],
                  choicesZakl: [[]],
                },
                {
                  title: "Резекция сегмента",
                  content:
                    "Состояние после резекции ... ... лёгкого, в зоне вмешательства регистрируются закономерные структурные изменения, соответствующие его объёму и срокам, в т.ч. ретикулярные фиброзные уплотнения с тракцией прилежащей лёгочной ткани и гиперденсными включениями шовного материала в составе; зона оперативного доступа без особенностей. На фоне изменений в рамках послеоперационных достоверных КТ-признаков местного рецидива основного заболевания не отмечено.  ",
                  zakl: "Состояние после резекции сегмента ... лёгкого.",
                  checked: false,
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                    ],
                    ["правого", "левого"],
                  ],
                  choicesZakl: [["правого", "левого"]],
                },
                {
                  title: "Лобэктомия",
                  content:
                    "Состояние после удаления ... доли ... лёгкого, в зоне вмешательства (в лёгочной ткани и воротах лёгкого) регистрируются закономерные структурные изменения, соответствующие его объёму и срокам, в т.ч. ретикулярные фиброзные уплотнения с тракцией прилежащей лёгочной ткани и гиперденсными включениями шовного материала в составе; зона оперативного доступа без особенностей. На фоне изменений в рамках послеоперационных достоверных КТ-признаков местного рецидива основного заболевания не отмечено.  ",
                  zakl: "Состояние после лобэктомии ... лёгкого. ",
                  choices: [
                    ["верхней", "средней", "нижней"],
                    ["правого", "левого"],
                  ],
                  choicesZakl: [["правого", "левого"]],
                },
                {
                  title: "Пульмонэктомия",
                  content:
                    "Состояние после удаления ... лёгкого, в зоне вмешательства регистрируются закономерные структурные изменения, соответствующие его объёму и срокам, полость гемиторакса заполнена однородным содержимым промежуточной плотности, средостение смещено в сторону вмешательства; зона оперативного доступа без особенностей. На фоне изменений в рамках послеоперационных достоверных КТ-признаков местного рецидива основного заболевания не отмечено.  ",
                  zakl: "Состояние после ... пульмонэктомии. ",
                  choices: [["правого", "левого"]],
                  choicesZakl: [["правосторонней", "левосторонней"]],
                },
              ],
              choices: [[]],
              choicesZakl: [[]],
            },
          ],
        },
        {
          title: "Трахеобронхиальное дерево",
          norm: "Трахея и крупные бронхи без особенностей. ",
          buttons: [
            {
              title: "Кальцинаты",
              content:
                "Элементы трахеобронхиального дерева обильно кальцинированы. ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Хронический бронхит",
              content:
                "Стенки магистральных бронхов утолщены, деформированы, в составе единичных бронхов дифференцируется внутрипросветное содержимое. ",
              zakl: "Косвенные КТ-признаки хронического бронхита. ",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Трахеостомия",
              content:
                "Состояние после трахеостомии, зона оперативного вмешательства без особенностей, конец трахеостомической трубки располагается выше уровня бифурации трахеи. ",
              zakl: "Состояние после трахеостомии. ",
              type: "checkbox",
              newline: true,
            },
            {
              type: "radio",
              title: "Бронхоэктазы",
              newline: true,
              radios: [
                {
                  title: "Цилиндрические",
                  content:
                    "В ... ... цилиндрические (протяжённые участки равномерного расширения)  бронхоэктазы ... бронхов. ",
                  zakl: "Цилиндрические бронхоэктазы .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    ["сегментарных", "субсегментарных"],
                  ],
                  choicesZakl: [
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Варикозные",
                  content:
                    "В ... ... варикозные (неравномерное расширение просвета бронхов, чередующееся с участками сужения) бронхоэктазы  ... бронхов. ",
                  zakl: "Варикозные бронхоэктазы .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    ["сегментарных", "субсегментарных"],
                  ],
                  choicesZakl: [
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
                {
                  title: "Кистозные",
                  content:
                    "В ... ... кистозные бронхоэктазы  ... бронхов ..., размерами до ... мм, . ",
                  zakl: "Кистозные бронхоэктазы .... ",
                  choices: [
                    [
                      "S1",
                      "S2",
                      "S3",
                      "S4",
                      "S5",
                      "S6",
                      "S7",
                      "S8",
                      "S9",
                      "S10",
                      "верхней доле",
                      "средней доле",
                      "нижней доле",
                      "правом лёгком",
                      "левом лёгком",
                      "обоих лёгких",
                    ],
                    ["правого лёгкого", "левого лёгкого"],
                    ["сегментарных", "субсегментарных"],
                    [
                      "без жидкостного содержимого",
                      "с газожидкостными уровнями",
                    ],
                    [],
                  ],
                  choicesZakl: [
                    ["правого лёгкого", "левого лёгкого", "обоих лёгких"],
                  ],
                },
              ],
              choices: [[]],
              choicesZakl: [[]],
            },
          ],
        },
        {
          title: "Плевральные полости",
          norm: "Плевральный выпот не дифференцируется. ",
          buttons: [
            {
              title: "Дренажи",
              content:
                "Состояние после дренирования ..., количество установленных дренажных трубок: ....",
              zakl: "Состояние после дренирования ... плевральной полости. ",
              type: "checkbox",
              newline: true,
              choices: [
                [
                  "правой плевральной полости",
                  "левой плевральной полости",
                  "обеих плевральных полостей",
                ],
                ["1", "2", "3"],
              ],
              choicesZakl: [["правой", "левой"]],
            },
            {
              title: "Пневмоторакс",
              content:
                "В ... дифференцируется свободный газ толщиной слоя до ... мм. ",
              zakl: "... пневмоторакс. ",
              type: "checkbox",
              newline: true,
              choices: [
                [
                  "правой плевральной полости",
                  "левой плевральной полости",
                  "обеих плевральных полостей",
                ],
                ["", "", ""],
              ],
              choicesZakl: [
                ["Правосторонний", "Левосторонний", "Двусторонний"],
              ],
            },
            {
              title: "Гидроторакс",
              content: "",
              zakl: "",
              type: "constructor",
              constructor: "Гидроторакс",
              newline: true,
            },
          ],
        },
      ],
    },
    {
      title: "СРЕДОСТЕНИЕ",
      segments: [
        {
          title: "Основной раздел",
          norm: "не смещено, в полости перикарда выпот не прослеживается. ",
          buttons: [
            {
              title: "ГПОД",
              content:
                "Кардиальный отдел желудка пролабирует в средостение через пищеводное отверстие диафрагмы, сформирован грыжевой мешок размерами до ... мм без признаков ущемления. ",
              zakl: "ГПОД. ",
              type: "checkbox",
              newline: false,
            },
            {
              title: "Гидроперикард",
              content:
                "В полости перикарда отмечается скопление свободной жидкости толщиной слоя до ... мм.  ",
              zakl: "Гидроперикард. ",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Кардиомегалия",
              content: "Сердце увеличено в размерах. ",
              zakl: "Кардиомегалия. ",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Ахалазия",
              content:
                "Просвет пищевода веретеновидно расширен до ... мм, содержит скопление гетерогенного содержимого. ",
              zakl: "Изменения пищевода, вероятнее, развиты в рамках ахалазии. ",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Гиперплазия тимуса",
              content:
                "Тимус с потерей признаков физиологичной инволюции, увеличен в размерах до ... мм, типичной гранулярной структуры с чередованием включений мягкотканной и жировой плотности. ",
              zakl: "Гиперплазия тимуса. ",
              type: "checkbox",
              newline: true,
            },
            {
              type: "radio",
              title: "Смещение",
              newline: true,
              radios: [
                {
                  title: "Влево",
                  content: "Средостение смещено влево. ",
                  zakl: "",
                },
                {
                  title: "Вправо",
                  content: "Средостение смещено вправо. ",
                  zakl: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "МЯГКИЕ ТКАНИ",
      segments: [
        {
          title: "Раздел 1",
          norm: "без особенностей.",
          buttons: [
            {
              type: "select",
              title: "Мастэктомия",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Слева",
                  content:
                    "Состояние после левосторонней радикальной мастэктомии, изменения в зоне вмешательства соответствуют его объёму и срокам, на фоне послеоперационных изменений достоверных признаков местного рецидива основного заболевания не отмечено. ",
                  zakl: "Состояние после правосторонней радикальной мастэктомии. ",
                  checked: false,
                },
                {
                  title: "Справа",
                  content:
                    "Состояние после правосторонней радикальной мастэктомии, изменения в зоне вмешательства соответствуют его объёму и срокам, на фоне послеоперационных изменений достоверных признаков местного рецидива основного заболевания не отмечено. ",
                  zakl: "Состояние после левосторонней радикальной мастэктомии. ",
                },
                {
                  title: "Билатерально",
                  content:
                    "Состояние после двусторонней радикальной мастэктомии, изменения в зоне вмешательства соответствуют его объёму и срокам, на фоне послеоперационных изменений достоверных признаков местного рецидива основного заболевания не отмечено. ",
                  zakl: "Состояние после двусторонней радикальной мастэктомии. ",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "ЛИМФОУЗЛЫ",
      segments: [
        {
          title: "Раздел 1",
          norm: "не увеличены. ",
          buttons: [
            {
              title: "Лимфаденопатия ОГК",
              content: "",
              zakl: "",
              type: "constructor",
              constructor: "Лимфаденопатия ОГК",
              newline: true,
              checked: false,
            },
          ],
        },
      ],
    },
    {
      title: "СОСУДЫ",
      segments: [
        {
          title: "Раздел 1",
          norm: "без особенностей. ",
          buttons: [
            {
              title: "Атеросклероз аорты, магистральных ветвей",
              content:
                "Стенки аорты и её магистральных ветвей содержат смешанные атеросклеротические бляшки. ",
              zakl: "Атеросклероз аорты, её магистральных ветвей. ",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Коронарный атеросклероз",
              content: "В составе коронарных артерий отмечаются кальцинаты. ",
              zakl: "Коронарный атеросклероз. ",
              type: "checkbox",
              newline: true,
            },
            {
              type: "select",
              title: "Аневризма/расширение грудной аорты",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Восходящая дилятация (40-50 мм)",
                  content: "Восходящий отдел аорты расширен до ... мм.",
                  zakl: "Дилятация восходящего отдела грудной аорты. ",
                  checked: false,
                },
                {
                  title: "Восходящая аневризма (>50 мм)",
                  content: "Восходящий отдел аорты расширен до ... мм.",
                  zakl: "Аневризма восходящего отдела грудной аорты. ",
                  checked: false,
                },
              ],
            },
            {
              title: "Расширение вен пищевода",
              content:
                "Вены параэзофагеального венозного сплетения и подслизистой оболочки пищевода на уровне его нижней трети расширены. ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
          ],
        },
      ],
    },
    {
      title: "КОСТНЫЕ СТРУКТУРЫ",
      segments: [
        {
          title: "Раздел 1",
          norm: "без травматических и деструктивных изменений. ",
          buttons: [
            {
              title: "Эностозы",
              content:
                "В губчатом веществе костей на уровне сканирования дифференцируются единичные эностозы (клинически незначимые островки компактной кости). ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Грыжи Шморля",
              content: "Грыжи Шморля на уровне сканирования. ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Оссификация передней продольной связки",
              content:
                "Передняя продольная связка утолщена, обильно кальцинирована. ",
              zakl: "",
              type: "checkbox",
              newline: true,
            },
            {
              title: "Ювенильный кифоз (Шауэрмана)",
              content:
                "Отмечается усиление грудного кифоза до ... градусов лёжа, клиновидная деформация тел грудных позвонков, выраженная деформация краниальных и каудальных замыкательных пластин тел позвонков с их избыточным остеосклерозом. ",
              zakl: "Ювенильный кифоз (болезнь Шауэрмана). ",
              type: "checkbox",
              newline: true,
            },
            {
              type: "select",
              title: "Остеохондроз",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Слабовыраженный",
                  content:
                    "Краниальные и каудальные замыкательные пластинки тел позвонков с участками избыточного остеосклероза, незначительно деформированы. ",
                  zakl: "Начальный остеохондроз. ",
                  checked: false,
                },
                {
                  title: "Умеренный",
                  content:
                    "Краниальные и каудальные замыкательные пластинки тел позвонков с участками избыточного остеосклероза, деформации, межпозвонковые щели неравномерно сужены (Thompson II-III). ",
                  zakl: "Умеренный остеохондроз. ",
                },
                {
                  title: "Выраженный",
                  content:
                    "Краниальные и каудальные замыкательные пластинки тел позвонков с участками выраженного остеосклероза, деформации, межпозвонковые щели неравномерно выраженно сужены (Thompson III-IV). ",
                  zakl: "Выраженный остеохондроз. ",
                },
              ],
            },
            {
              type: "select",
              title: "Спондилёз",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Начальный",
                  content:
                    "Отмечаются слабовыраженные краевые остеофиты по переднему контуру тел позвонков. ",
                  zakl: "Слабовыраженный  спондилёз. ",
                  checked: false,
                },
                {
                  title: "Умеренный",
                  content:
                    "Вентральные контуры тел позвонков деформированы за счёт умеренного спондилёза. ",
                  zakl: "Умеренный спондилёз. ",
                },
                {
                  title: "Выраженный",
                  content:
                    "Вентральные контуры тел позвонков выраженно деформированы за счёт развитого спондилёза, передняя продольная связка обильно кальцинирована. ",
                  zakl: "Выраженный спондилёз. ",
                },
              ],
            },
            {
              type: "select",
              title: "Спондилоартроз",
              newline: true,
              options: [
                {
                  title: "-",
                  content: "",
                  zakl: "",
                  checked: true,
                },
                {
                  title: "Начальный",
                  content:
                    "Начальные признаки спондилоартроза в виде избыточного субхондрального остеосклероза, сужения суставных щелей дугоотросчатых суставов. ",
                  zakl: "Начальный спондилоартроз. ",
                  checked: false,
                },
                {
                  title: "Умеренный ",
                  content:
                    "Суставные щели дугоотросчатых суставов неравномерно сужены, отмечается избыточный субхондральный остеосклероз, краевые остеофиты. ",
                  zakl: "Умеренный спондилоартроз. ",
                },
                {
                  title: "Выраженный",
                  content:
                    "Суставные щели дугоотросчатых суставов выраженно сужены, отмечается развитый избыточный субхондральный остеосклероз, грубые краевые остеофиты, субхондральные кисты и утолщение жёлтых связок. ",
                  zakl: "Выраженный спондилоартроз. ",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "ПРОЧИЕ ИЗМЕНЕНИЯ",
      segments: [
        {
          title: "Раздел 1",
          norm: "",
          buttons: [
            {
              title: "Например, объёмное образование",
              content:
                "В ... дифференцируется ... объёмное образование с ..., ... контурами, ....",
              zakl: "... объёмное образование ....",
              type: "checkbox",
              newline: true,
              checked: false,
              choices: [
                ["голове", "шее", "руке"],
                ["кистозное", "солидное", "кистозно-солидное"],
                ["чёткими", "нечёткими"],
                ["ровными", "неровными"],
                [
                  "не накапливающее контрастное вещество",
                  "накапливающее контрастное вещество по периферии",
                  "неоднородно накапливающее контрастное вещество по всей площади",
                  "гомогенно накапливающее контрастное вещество",
                ],
              ],
              choicesZakl: [
                ["Солидное", "Кистозное", "Кистозно-солидное"],
                ["головы", "руки", "шеи"],
              ],
            },
          ],
        },
      ],
    },
    {
      title: "ЗАКЛЮЧЕНИЕ",
      zakl: true,
      segments: [
        {
          title: "ЗАКЛЮЧЕНИЕ",
          norm: "Достоверных признаков патологических изменений не отмечено. ",
          buttons: [
            {
              title: "Норма",
              type: "checkbox",
              newline: false,
              zakl: "",
              content:
                "Достоверных признаков патологических изменений не отмечено. ",
            },
            {
              title: "Без динамики",
              type: "checkbox",
              newline: false,
              zakl: "",
              content: "В сравнении с исследованием от ... без динамики. ",
            },
          ],
        },
      ],
    },
  ],
  createdAt: "2024-10-17T12:13:03.042Z",
  updatedAt: "2025-01-01T11:59:47.359Z",
  __v: 0,
  averageRating: 8.5,
  ratingsLength: 6,
  favorite: true,
  numberTimesOpened: 23,
  numberTimesUsed: 1,
  images: [],
  shpora: [],
};

export default rybaForTutorial;
