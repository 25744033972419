import TextareaAutosize from "react-textarea-autosize";
import Duplicate from "../../svg/Duplicate";
import ArrowUp from "../../svg/ArrowUp";
import ArrowDown from "../../svg/ArrowDown";
import DeleteIcon from "../../svg/DeleteIcon";
import { useState } from "react";
import ChoicesConstructor from "./ChoicesConstructor";
import ChoicesConstructorZakl from "./ChoicesConstructorZakl";

const newRadio = {
  title: "Название опции",
  content: "Текст опции. ",
  options: [[]],
};

const RadioRedactor = ({
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  notification,
  setChoicesZakl,
  setChoices,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [whichRadio, setWhichRadio] = useState();
  const [optionsOpen, setOptionsOpen] = useState(false);

  return (
    <>
      <div className="buttonEditingSegmentRADIOorSELECT">
        <div className="buttonTITLE2">Название опции</div>
        <div className="buttonCONTENT2">Текст опции</div>
        <div className="buttonZAKL2">В заключение</div>
      </div>
      {button &&
        button.radios.map((radio, index) => (
          <>
            <div className="buttonEditingSegmentRADIOorSELECT">
              <div className="buttonTITLE2">
                <TextareaAutosize
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].title
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].title = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>
              <div className="buttonCONTENT2">
                {" "}
                <TextareaAutosize
                  placeholder="-"
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].content
                  }
                  onChange={(e) => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].content = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>

              <div className="buttonZAKL2">
                <TextareaAutosize
                  placeholder="-"
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].zakl
                  }
                  onChange={(e) => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].zakl = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>
              <div className="optionsandradioseditdiv">
                <button
                  className="svgButton"
                  onClick={() => {
                    let r = { ...rybaObject };
                    const newRadio = JSON.parse(
                      JSON.stringify(
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index]
                      )
                    );

                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios.splice(index, 0, newRadio);

                    setRybaObject(r);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    notification("Опция скопирована");
                    let r = { ...rybaObject };
                    window.localStorage.setItem(
                      `copiedRADIO`,
                      JSON.stringify(
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index]
                      )
                    );
                  }}
                >
                  <Duplicate />
                </button>

                {index > 0 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      let a =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index];
                      let b =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index - 1];
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index] = b;
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index - 1] = a;
                      setRybaObject(r);
                    }}
                  >
                    <ArrowUp />
                  </button>
                )}
                {index < button.radios.length - 1 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      let a =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index];
                      let b =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index + 1];
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index] = b;
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index + 1] = a;
                      setRybaObject(r);
                    }}
                  >
                    <ArrowDown />
                  </button>
                )}

                {rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].radios.length > 1 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios.splice(index, 1);
                      setRybaObject(r);
                    }}
                  >
                    <DeleteIcon width={24} height={24} />
                  </button>
                )}
              </div>
            </div>
            {Object.hasOwn(radio, "choices") && (
              <ChoicesConstructor
                buttonItself={radio}
                setChoices={setChoices}
                buttonType={"radio"}
                organIndex={organIndex}
                segmentIndex={segmentIndex}
                buttonIndex={buttonIndex}
                radioIndex={index}
                optionIndex={undefined}
              />
            )}
            {Object.hasOwn(radio, "choicesZakl") && (
              <ChoicesConstructorZakl
                buttonItself={radio}
                setChoicesZakl={setChoicesZakl}
                buttonType={"radio"}
                organIndex={organIndex}
                segmentIndex={segmentIndex}
                buttonIndex={buttonIndex}
                radioIndex={index}
                optionIndex={undefined}
              />
            )}
          </>
        ))}
      <div>
        <button
          onClick={() => {
            let r = { ...rybaObject };
            let nr = { ...newRadio };
            if (
              Object.hasOwn(
                r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
                  .radios[0],
                "choices"
              )
            ) {
              nr.choices = [];
            }
            if (
              Object.hasOwn(
                r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
                  .radios[0],
                "choicesZakl"
              )
            ) {
              nr.choicesZakl = [];
            }
            r.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].radios.push(nr);
            setRybaObject(r);
          }}
        >
          ➕
        </button>
      </div>
    </>
  );
};
export default RadioRedactor;
