import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import spleen from "../../images/Spleen.png";

const Splenomegaly = ({
  constructorREF,
  setConstructorOpen,
  text,
  setText,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [size3, setSize3] = useState(null);
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let x = 30 + xx * zz * yy * 0.58;
    x = Math.round(x);

    let y = xx * zz * yy;
    y = Math.round(y);

    let finalMeasure = "";
    if (x !== "0" && x <= 314) {
      finalMeasure = `Селезёнка не увеличена (размеры в трёх плоскостях ${xx}x${yy}x${zz} см, расчётный объём составляет ${x} см3, норма <314см3, селезёночный индекс ${y}, норма <480).`;

      zaklStringFinal = "";
    }
    if (x !== "0" && x > 314) {
      finalMeasure = `Селезёнка увеличена в размерах (размеры в трёх плоскостях ${xx}x${yy}x${zz} см, объём составляет ${x} см3, норма <314см3, селезёночный индекс ${y}, норма <480).`;

      zaklStringFinal = `Спленомегалия (объём ${x} см3, СИ ${y}). `;
    }
    console.log(finalMeasure);
    ///////

    ///////
    if (dinamika) {
      dinamikaConstructorFunction(finalMeasure, zaklStringFinal);
      setConstructorOpen(false);
      return;
    }
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }
    if (!t[rybaLength][0].includes("пленомегалия")) {
      t[rybaLength][0] += zaklStringFinal;
    }
    setText(t);
    setConstructorOpen(false);
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h1 key={(p += 1)}>Расчёт объёма селезёнки</h1>
      <p>Введи циферки, нажми Enter и наслаждайся</p>

      <p className="name">Селезёнка</p>
      <img
        src={spleen}
        alt="spleen"
        style={{
          width: "400px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      />
      <div className="inputVertical">
        <input
          autoFocus
          type="text"
          placeholder="Размер 1 в cм"
          value={size1}
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="text"
          placeholder="Размер 2 в cм"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="text"
          placeholder="Размер 3 в cм"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>

      <button
        className="calculate"
        onClick={() => {
          // calculate();
          textHandler();
        }}
      >
        Рассчитать
      </button>
      {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      <button
        className="calculate"
        onClick={() => {
          // calculate();
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default Splenomegaly;
