import Img from "./Img";

const OverlayImages = ({ images, setOverlayImages }) => {
  return (
    <div className="overlayImagesContainer">
      <div
        className="closeButtonsButton"
        onClick={() => {
          setOverlayImages(false);
        }}
      >
        ❌
      </div>
      {images.map((image, index) => (
        <Img src={image.originalSrc} />
      ))}
    </div>
  );
};
export default OverlayImages;
