import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { user } = useAuthContext();
  // const [username, setUsername] = useState("");
  // const [firstname, setFirstname] = useState("");
  // const [middlename, setMiddlename] = useState("");
  // const [lastname, setLastname] = useState("");
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      return alert("Введённые пароли не совпадают");
    }

    await signup(email, password);

    console.log(email, password);
  };

  return (
    <form className="signup login" onSubmit={handleSubmit}>
      {!user && (
        <>
          <h3>Регистрация в рыбах</h3>
          <p style={{ fontSize: "13px" }}>
            Укажите адрес почты, которой пользуетесь, чтобы в случае чего можно
            было сбросить забытый пароль
          </p>

          <label>Электронная почта:</label>
          <input
            className="inputLoginSignup"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <label>Придумайте пароль:</label>
          <input
            className="inputLoginSignup"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <label>Введите придуманный пароль ещё раз:</label>
          <input
            className="inputLoginSignup"
            type="password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            value={passwordConfirm}
          />

          <button
            disabled={isLoading}
            className="loginButton"
            style={{
              width: "200px",
            }}
          >
            Зарегистрироваться
          </button>
          <p style={{ fontSize: "13px" }}>Уже есть аккаунт?</p>
          <Link to="/login">
            <button
              className="loginButton"
              style={{
                scale: "0.8",
              }}
            >
              У меня есть аккаунт
            </button>
          </Link>
        </>
      )}
      {user && (
        <>
          <h2>Спасибо за регистрацию!</h2>
          <Link to="/login">
            <button>Начать заканчивать пораньше</button>
          </Link>
        </>
      )}
      {error && (
        <div
          className="error"
          style={{
            color: "red",
          }}
        >
          {error}
        </div>
      )}
    </form>
  );
};

export default Signup;
