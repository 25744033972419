import { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { useParams, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import "./RybaRedactor2.css";
import "./Popup.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import { textState2 } from "../state/atoms/textareaText2";
import { fullRYBAatom2 } from "../state/atoms/fullRYBAatom2";
import AreYouSure from "./smallComponents/AreYouShure";
import { Tooltip } from "react-tooltip";
import { protocolsCT } from "../data/protocolsCT";
import { protocolsMRI } from "../data/protocolsMRI";
/// SVGs

import DeleteIcon from "./svg/DeleteIcon";
import ArrowDown from "./svg/ArrowDown";
import ArrowUp from "./svg/ArrowUp";
import Duplicate from "./svg/Duplicate";
import Dropdown from "./svg/Dropdown";
import CheckboxsVG from "./svg/CheckboxSVG";
import RadioSVG from "./svg/RadioSVG";
import ConstructorSVG from "./svg/ConstructorSVG";

/// components

import Notification from "./editing/NotificationWindow";
import ButtonsRedactor from "./smallComponents/buttonsRedactor";
import ImagesToTheRightRedactor from "./smallComponents/ImagesToTheRightRedactor";
import ZoomIn from "./svg/ZoomIn";
import ZoomOut from "./svg/ZoomOut";

const Ryba = () => {
  const { user } = useAuthContext();
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom2);
  const [text, setText] = useRecoilState(textState2); // recoil state of the textarea
  const { id } = useParams();
  const [organTitle, setOrganTitle] = useState("");
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  // windows states
  const [isRybaShared, setIsRybaShared] = useState(false);
  const [editedSegment, setEditedSegment] = useState(0);
  const [editedOrgan, setEditedOrgan] = useState(2);
  const [originalRyba, setOriginalRyba] = useState(null);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [modalityState, setModalityState] = useState(undefined);
  const [localStorageTXTtemp, setLocalStorageTXTtemp] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [organContextMenu, setOrganContextMenu] = useState(false);
  const [topOrganContextMenu, setTopOrganContextMenu] = useState(undefined);
  const [leftOrganContextMenu, setLeftOrganContextMenu] = useState(undefined);
  const [segmentContextMenu, setSegmentContextMenu] = useState(false);
  const [topSegmentContextMenu, setTopSegmentContextMenu] = useState(undefined);
  const [leftSegmentContextMenu, setLeftSegmentContextMenu] =
    useState(undefined);
  const [buttonContextMenu, setButtonContextMenu] = useState(false);
  const [topButtonContextMenu, setTopButtonContextMenu] = useState(undefined);
  const [leftButtonContextMenu, setLeftButtonContextMenu] = useState(undefined);
  const [contextHighlight, setContextHighlight] = useState();
  const [rightImagesRedactor, setRightImagesRedactor] = useState(false);
  const [zoom, setZoom] = useState();

  // window refs
  let organContextMenuRef = useRef();
  let segmentContextMenuRef = useRef();
  let buttonContextMenuRef = useRef();

  // variables for requests
  let notificationText = useRef();
  let newSegmentNorm = useRef("");
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  // useNavigate consts
  const navigate = useNavigate();

  useEffect(() => {
    const getFullRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      const r = { ...json };
      let localStorageTXT = JSON.parse(localStorage.getItem(id));
      const z = JSON.parse(localStorage.getItem("zoomRedactor"));
      setZoom(z);
      if (!z) {
        setZoom(1);
      }
      /////////
      setIsRybaShared(r.shared);
      if (response.ok) {
        setRybaObject(r);
        setModalityState(r.modality);
        setOriginalRyba(r);
        const t = [];
        r.organs.map((o, organIndex) => {
          t.push([]);
          o.segments.map((s) => {
            t[organIndex].push(s.norm);
          });
          setText(t);
        });
        if (localStorageTXT) {
          setLocalStorageTXTtemp(localStorageTXT);
        }
      }
    };
    if (user) {
      getFullRyba();
    }
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;

  /////////////////////////////////////////////////////////////////
  ////////////////////////// ⌨️ TEXTAREAS STATE ⌨️ //////////////////////////

  // update textState
  const updateTextState = () => {
    let r = { ...rybaObject };
    let t = [];
    r.organs.map((o, organIndex) => {
      t.push([]);
      o.segments.map((s) => {
        t[organIndex].push(s.norm);
      });
      setText(t);
    });
  };

  // handling RYBA title edit in textarea

  /////////////////////////////////////////////////////////////////
  ////////////////////////// ☎️ REQUESTS ☎️ //////////////////////////

  //////////////// button requests ////////////////////

  // DELETE button request
  const deleteButtonRequest = function (
    e,
    buttonIndex,
    segmentIndex,
    organIndex
  ) {
    const r = { ...rybaObject };
    r.organs[organIndex].segments[segmentIndex].buttons.splice(buttonIndex, 1);
    setRybaObject(r);

    notification("Кнопка успешно удалена");
  };

  // CREATE checkbox
  const createNewCheckbox = function (e) {
    const newButton = {
      title: "Название кнопки",
      content: "Текст кнопки. ",
      zakl: "",
      type: "checkbox",
      newline: true,
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notification("Кнопка успешно создана");
  };

  const createNewConstructor = function (e) {
    const newButton = {
      title: "Кисты почек Bosniak I",
      content: "",
      zakl: "",
      type: "constructor",
      constructor: "renalCysts",
      options: undefined,
      newline: true,
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notification("Кнопка успешно создана");
  };

  const createNewRadio = function () {
    const newButton = {
      type: "radio",

      title: "Название группы кнопок",
      newline: true,
      radios: [
        {
          title: "Название кнопки",
          content: "Текст кнопки. ",
          zakl: "",
        },
      ],
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notification("Кнопка успешно создана");
  };

  const createNewSelect = function () {
    const newButton = {
      type: "select",
      title: "Название меню",
      newline: true,
      options: [
        {
          title: "-",
          content: "",
          zakl: "",
        },
        {
          title: "Название опции",
          content: "Текст опции. ",
          zakl: "",
        },
      ],
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notification("Кнопка успешно создана");
  };

  ///////////////////////////////   DUPLICATE BUTTON    ////////////////////////////////////
  const duplicateButton = function (e) {
    // arr.splice(index, 0, item);
    const r = { ...rybaObject };
    const copiedButton = JSON.parse(
      JSON.stringify(
        r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
          buttonINDEX.current
        ]
      )
    );

    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.splice(
      buttonINDEX.current,
      0,
      copiedButton
    );

    setRybaObject(r);

    notification("Кнопка дублирована");
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////     segment requests      ////////////////////////////

  // CREATE segment request
  const createNewSegment = function (e) {
    const newSegment = {
      title: "Новый раздел",
      norm: "",
      buttons: [],
    };

    const s = { ...rybaObject };
    // arr.splice(index, 0, item

    const r = { ...rybaObject };

    r.organs[organINDEX.current].segments.push(newSegment);
    setRybaObject(r);

    newSegmentNorm.current = "";
    // local txt
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l[organINDEX.current].push("");
      setLocalStorageTXTtemp(l);
    }
    //
  };

  // DELETE segment request
  const deleteSegmentRequest = function (e, segmentIndex, organIndex) {
    const r = { ...rybaObject };

    r.organs[organIndex].segments.splice(segmentIndex, 1);

    setRybaObject(r);

    // local txt
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l[organIndex].splice(segmentIndex, 1);
      setLocalStorageTXTtemp(l);
    }
    //
  };

  ////////// DUPLICATE SEGMENT REQUEST //////////
  const duplicateSegment = function (e) {
    const r = { ...rybaObject };
    const copiedSegment = JSON.parse(
      JSON.stringify(
        r.organs[organINDEX.current].segments[segmentINDEX.current]
      )
    );
    r.organs[organINDEX.current].segments.splice(
      segmentINDEX.current,
      0,
      copiedSegment
    );
    setRybaObject(r);
    updateTextState();
    // local txt
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l[organINDEX.current].splice(
        segmentINDEX.current,
        1,
        l[organINDEX.current][segmentINDEX.current]
      );
      setLocalStorageTXTtemp(l);
    }
    //
  };

  //////////////////////// ryba requests /////////////////////////

  const saveRybaChanges = async (req, res) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...rybaObject,
        }),
      }
    );
    const json = await response.json();
    console.log(response);
    if (response.ok) {
      setSaving(false);
      notification("Изменения успешно сохранены");
      console.log(json);
    }
    if (!response.ok) {
      console.log(json);
    }
  };

  // share RYBA
  const shareRyba = function (e) {
    const shareRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            shared: `true`,
          }),
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json);
      }
    };
    shareRyba();
    setIsRybaShared(true);
  };

  // hide RYBA
  const hideRyba = function (e) {
    const hideR = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            shared: `false`,
          }),
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json);
      }
    };
    hideR();
    setIsRybaShared(false);
  };

  // delete ryba request

  const deleteRybaRequest = function (e) {
    const deleteRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        console.log(json);
        navigateHome();
      }
    };
    deleteRyba();
  };

  // navigation functions

  const navigateHome = () => {
    navigate("/");
  };

  // notification function
  const notification = function (message) {
    notificationText.current = message;
    console.log(notificationText.current);
    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1500);
  };

  // move UPWARD segment /////////////
  const moveSegmentUpward = (organIndex, segmentIndex) => {
    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex];
    const b = r.organs[organIndex].segments[segmentIndex - 1];
    r.organs[organIndex].segments[segmentIndex] = b;
    r.organs[organIndex].segments[segmentIndex - 1] = a;
    setRybaObject(r);
    notification("Хоба");
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organIndex][segmentIndex];
      const d = l[organIndex][segmentIndex - 1];
      l[organIndex][segmentIndex] = d;
      l[organIndex][segmentIndex - 1] = c;
      setLocalStorageTXTtemp(l);
    }
    //
  };

  // move DOWNWARD segment

  const moveSegmentDownward = (organIndex, segmentIndex) => {
    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex];
    const b = r.organs[organIndex].segments[segmentIndex + 1];
    r.organs[organIndex].segments[segmentIndex] = b;
    r.organs[organIndex].segments[segmentIndex + 1] = a;
    setRybaObject(r);
    notification("Хоба");
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organIndex][segmentIndex];
      const d = l[organIndex][segmentIndex + 1];
      l[organIndex][segmentIndex] = d;
      l[organIndex][segmentIndex + 1] = c;
      setLocalStorageTXTtemp(l);
    }
    //
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// MOVE BUTTON UPWARD //////////////////////////////////////

  const moveButtonUpward = (organIndex, segmentIndex, buttonIndex) => {
    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    const b =
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex - 1];
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex] = b;
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex - 1] = a;
    setRybaObject(r);
    notification("Хоба");
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// MOVE BUTTON DOWNWARD //////////////////////////////////////
  const moveButtonDownward = (organIndex, segmentIndex, buttonIndex) => {
    console.log(organIndex, segmentIndex, buttonIndex);

    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    const b =
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex + 1];
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex] = b;
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex + 1] = a;
    setRybaObject(r);
    notification("Хоба");
  };
  ////////////////////// ORGAN REQUESTS ///////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // CREATE organ request
  const createOrgan = function (e) {
    const newOrgan = {
      title: "НОВЫЙ ОРГАН",
      segments: [
        {
          title: "Раздел 1",
          norm: "Патологических изменений не отмечено. ",
          buttons: [],
        },
      ],
    };

    const s = { ...rybaObject };

    s.organs.splice(s.organs.length - 1, 0, newOrgan);
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l.splice(s.organs.length - 1, 0, [newOrgan.segments[0].norm]);
      console.log(l);
      setLocalStorageTXTtemp(l);
    }
    setRybaObject(s);
    updateTextState();
    setOrganTitle("");

    setEditedOrgan(s.organs.length - 2);
  };

  // // DELETE organ request
  const deleteOrgan = function (e) {
    const r = { ...rybaObject };
    r.organs.splice(organINDEX.current, 1);

    setRybaObject(r);
    updateTextState();
    notification("Орган удалён");
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      l.splice(organINDEX.current, 1);
      setLocalStorageTXTtemp(l);
    }
  };

  // MOVE ORGAN UP
  const moveOrganUP = function () {
    const r = { ...rybaObject };
    const aboveOrganIndex = Number(organINDEX.current) - Number(1);
    const a = r.organs[organINDEX.current];
    const b = r.organs[aboveOrganIndex];

    r.organs[organINDEX.current] = b;
    r.organs[aboveOrganIndex] = a;
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organINDEX.current];
      const d = l[aboveOrganIndex];
      l[organINDEX.current] = d;
      l[aboveOrganIndex] = c;
      setLocalStorageTXTtemp(l);
    }
    //
    setRybaObject(r);
    updateTextState();

    notification("Орган перемещён");
  };

  // MOVE ORGAN DOWN
  const moveOrganDOWN = function () {
    const r = { ...rybaObject };
    const belowOrganIndex = Number(organINDEX.current) + Number(1);
    const a = r.organs[organINDEX.current];
    const b = r.organs[belowOrganIndex];
    r.organs[organINDEX.current] = b;
    r.organs[belowOrganIndex] = a;
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organINDEX.current];
      const d = l[belowOrganIndex];
      l[organINDEX.current] = d;
      l[belowOrganIndex] = c;
      setLocalStorageTXTtemp(l);
    }
    //
    setRybaObject(r);
    updateTextState();

    notification("Орган перемещён");
  };

  // DUPLICATE ORGAN
  const duplicateOrgan = function () {
    const r = { ...rybaObject };
    const copiedOrgan = JSON.parse(
      JSON.stringify(r.organs[organINDEX.current])
    );
    r.organs.splice(organINDEX.current, 0, copiedOrgan);
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      l.splice(organINDEX.current, 0, l[organINDEX.current]);
      setLocalStorageTXTtemp(l);
    }
    //
    setRybaObject(r);
    updateTextState();

    notification("Орган дублирован");
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////

  const locateSegment = (organIndex, segmentIndex) => {
    organINDEX.current = organIndex;
    segmentINDEX.current = segmentIndex;
  };

  const locateButton = (organIndex, segmentIndex, buttonIndex) => {
    organINDEX.current = organIndex;
    segmentINDEX.current = segmentIndex;
    buttonINDEX.current = buttonIndex;
  };

  const closeOpenOrganContextMenu = (e) => {
    if (organContextMenu && !organContextMenuRef.current?.contains(e.target)) {
      setOrganContextMenu(false);
    }
    if (
      buttonContextMenu &&
      !buttonContextMenuRef.current?.contains(e.target)
    ) {
      setButtonContextMenu(false);
    }
    if (
      segmentContextMenu &&
      !segmentContextMenuRef.current?.contains(e.target)
    ) {
      setSegmentContextMenu(false);
    }
  };
  document.addEventListener("mousedown", closeOpenOrganContextMenu);

  const contextMenuButton = (e, organIndex, segmentIndex, buttonIndex) => {
    e.preventDefault();
    organINDEX.current = organIndex;
    segmentINDEX.current = segmentIndex;
    buttonINDEX.current = buttonIndex;
    setLeftButtonContextMenu(e.pageX);
    setTopButtonContextMenu(e.pageY);
    setButtonContextMenu(true);
  };

  const uploadImage = async (e, image) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image, image.name);
    console.log(formData);
    const response = await fetch(`https://api.defaultuploader.com/v1/upload`, {
      method: "POST",
      headers: {
        Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
      },
      body: formData,
    });

    console.log(response);
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      console.log(response);

      let r = { ...rybaObject };
      r.images.push(json);
      console.log(r.images);
      setRybaObject(r);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////   REACT RETURN   //////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div
      className="redactorContainer"
      style={{
        zoom: zoom,
      }}
    >
      {rightImagesRedactor && (
        <ImagesToTheRightRedactor
          {...{ rybaObject, setRybaObject, setRightImagesRedactor }}
        />
      )}
      {areYouSureOpen && (
        <AreYouSure {...{ setAreYouSureOpen, deleteRybaRequest }} />
      )}
      {organContextMenu && (
        <div
          className="contextMenu"
          ref={organContextMenuRef}
          style={{
            top: `${topOrganContextMenu}px`,
            left: `${leftOrganContextMenu}px`,
            zIndex: "50",
            width: "120px",
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              notification("Орган скопирован");
              window.localStorage.setItem(
                `copiedORGAN`,
                JSON.stringify(rybaObject.organs[organINDEX.current])
              );
              setOrganContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            Копировать
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              notification("Орган скопирован");
              window.localStorage.setItem(
                `copiedORGAN`,
                JSON.stringify(rybaObject.organs[organINDEX.current])
              );

              deleteOrgan();
              setOrganContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            Вырезать
          </button>
          <button
            onClick={(e) => {
              if (!JSON.parse(window.localStorage.getItem("copiedORGAN"))) {
                alert("Нет скопированных органов");
                return;
              }
              const copiedOrgan = JSON.parse(
                window.localStorage.getItem("copiedORGAN")
              );
              let r = { ...rybaObject };
              const length = r.organs.length - 1;
              r.organs.splice(organINDEX.current + 1, 0, copiedOrgan);
              setRybaObject(r);
              notification("Орган из буфера обмена добавлен");
              if (localStorageTXTtemp) {
                let l = [...localStorageTXTtemp];
                let sa = [];
                copiedOrgan.segments.map((s) => {
                  sa.push(s.norm);
                });
                l.splice(organINDEX.current + 1, 0, sa);
                setLocalStorageTXTtemp(l);
              }
              setEditedOrgan((o) => o + 1);
              setOrganContextMenu(false);
            }}
          >
            Вставить
          </button>

          <button
            onClick={(e) => {
              duplicateOrgan();
              setEditedOrgan((o) => o + 1);
              setEditedSegment(0);
              setOrganContextMenu(false);
            }}
          >
            Дублировать
          </button>
          {organINDEX.current > 2 && (
            <button
              onClick={(e) => {
                moveOrganUP();
                setEditedOrgan((o) => o - 1);
                setEditedSegment(0);
                setOrganContextMenu(false);
              }}
            >
              Сдвинуть вверх
            </button>
          )}
          {organINDEX.current < rybaObject.organs.length - 2 && (
            // <div className="contextMenuButtonDiv">
            //   <div className="svgButtonDiv">
            //     <ArrowDown />
            //   </div>
            <button
              onClick={(e) => {
                moveOrganDOWN();
                setEditedOrgan((o) => o + 1);
                setEditedSegment(0);
                setOrganContextMenu(false);
              }}
            >
              Сдвинуть вниз
            </button>
            // </div>
          )}

          <button
            className="redOnHover"
            onClick={(e) => {
              deleteOrgan();
              setOrganContextMenu(false);
            }}
          >
            Удалить
          </button>
          <button onClick={() => setOrganContextMenu(false)}>Закрыть</button>
        </div>
      )}
      {segmentContextMenu && editedOrgan !== rybaObject.organs.length - 1 && (
        <div
          className="contextMenu"
          ref={segmentContextMenuRef}
          style={{
            top: `${topSegmentContextMenu}px`,
            left: `${leftSegmentContextMenu}px`,
            zIndex: "50",
            width: "120px",
          }}
        >
          <button
            onClick={(e) => {
              notification("Раздел скопирован");
              window.localStorage.setItem(
                `copiedSEGMENT`,
                JSON.stringify(
                  rybaObject.organs[editedOrgan].segments[editedSegment]
                )
              );
              setSegmentContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            Копировать
          </button>
          <button
            onClick={(e) => {
              notification("Раздел скопирован");
              window.localStorage.setItem(
                `copiedSEGMENT`,
                JSON.stringify(
                  rybaObject.organs[editedOrgan].segments[editedSegment]
                )
              );
              deleteSegmentRequest(e, editedSegment, editedOrgan);
              setSegmentContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            Вырезать
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();

              if (!window.localStorage.getItem("copiedSEGMENT")) {
                alert("В буфере обмена нет скопированных разделов");
                return;
              }

              let r = { ...rybaObject };
              const newSegment = JSON.parse(
                window.localStorage.getItem("copiedSEGMENT")
              );
              r.organs[editedOrgan].segments.splice(
                segmentINDEX.current + 1,
                0,
                newSegment
              );
              notification("Раздел из буфера обмена добавлен");
              setRybaObject(r);
              if (localStorageTXTtemp) {
                let l = [...localStorageTXTtemp];
                l[editedOrgan].splice(
                  segmentINDEX.current + 1,
                  0,
                  newSegment.norm
                );
                setLocalStorageTXTtemp(l);
              }
              setSegmentContextMenu(false);
            }}
          >
            Вставить
          </button>

          <button
            onClick={(e) => {
              locateSegment(editedOrgan, editedSegment);
              duplicateSegment();
              setEditedSegment((o) => o + 1);
              setSegmentContextMenu(false);
            }}
          >
            Дублировать
          </button>
          {segmentINDEX.current > 0 && (
            <button
              onClick={(e) => {
                organINDEX.current = editedOrgan;
                segmentINDEX.current = editedSegment;
                moveSegmentUpward(editedOrgan, editedSegment);
                setEditedSegment((o) => o - 1);
                setSegmentContextMenu(false);
              }}
            >
              Сдвинуть вверх
            </button>
          )}
          {segmentINDEX.current <
            rybaObject.organs[editedOrgan].segments.length - 1 && (
            <button
              onClick={(e) => {
                organINDEX.current = editedOrgan;
                segmentINDEX.current = editedSegment;
                moveSegmentDownward(editedOrgan, editedSegment);
                setEditedSegment((o) => o + 1);
                setSegmentContextMenu(false);
              }}
            >
              Сдвинуть вниз
            </button>
          )}

          {rybaObject.organs[editedOrgan].segments.length > 1 && (
            <button
              className="redOnHover"
              onClick={(e) => {
                deleteSegmentRequest(e, editedSegment, editedOrgan);
                setSegmentContextMenu(false);
              }}
            >
              Удалить
            </button>
          )}
          <button onClick={() => setSegmentContextMenu(false)}>Закрыть</button>
        </div>
      )}
      {buttonContextMenu && (
        <div
          className="contextMenu"
          ref={buttonContextMenuRef}
          style={{
            top: `${topButtonContextMenu}px`,
            left: `${leftButtonContextMenu}px`,
            zIndex: "50",
            width: "130px",
          }}
        >
          <button
            onClick={(e) => {
              notification("Кнопка скопирована");
              let r = { ...rybaObject };
              window.localStorage.setItem(
                `copiedBUTTON`,
                JSON.stringify(
                  r.organs[organINDEX.current].segments[segmentINDEX.current]
                    .buttons[buttonINDEX.current]
                )
              );
              setButtonContextMenu(false);
            }}
          >
            Копировать
          </button>
          <button
            onClick={(e) => {
              let r = { ...rybaObject };
              window.localStorage.setItem(
                `copiedBUTTON`,
                JSON.stringify(
                  r.organs[organINDEX.current].segments[segmentINDEX.current]
                    .buttons[buttonINDEX.current]
                )
              );
              deleteButtonRequest(
                e,
                buttonINDEX.current,
                segmentINDEX.current,
                organINDEX.current
              );
              setButtonContextMenu(false);
            }}
          >
            Вырезать
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (!window.localStorage.getItem("copiedBUTTON")) {
                alert("В буфере обмена нет скопированных кнопок");
                return;
              }
              let r = { ...rybaObject };
              const newButton = JSON.parse(
                window.localStorage.getItem("copiedBUTTON")
              );
              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons.splice(buttonINDEX.current + 1, 0, newButton);
              notification("Кнопка из буфера обмена добавлена");
              setRybaObject(r);
              setButtonContextMenu(false);
            }}
          >
            Вставить
          </button>

          <button
            onClick={(e) => {
              duplicateButton();
              setButtonContextMenu(false);
            }}
          >
            Дублировать
          </button>
          {buttonINDEX.current > 0 && (
            <button
              onClick={(e) => {
                moveButtonUpward(
                  organINDEX.current,
                  segmentINDEX.current,
                  buttonINDEX.current
                );
                setButtonContextMenu(false);
              }}
            >
              Сдвинуть вверх
            </button>
          )}
          {buttonINDEX.current <
            rybaObject.organs[editedOrgan].segments[editedSegment].buttons
              .length -
              1 && (
            <button
              onClick={(e) => {
                moveButtonDownward(
                  organINDEX.current,
                  segmentINDEX.current,
                  buttonINDEX.current
                );
                setButtonContextMenu(false);
              }}
            >
              Сдвинуть вниз
            </button>
          )}
          {!Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choices"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type !== "constructor" && (
              <button
                onClick={(e) => {
                  let r = { ...rybaObject };
                  const button =
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current];

                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choices = [[]];

                  if (button.type === "radio") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].radios.map((r) => {
                      r.choices = [[]];
                    });
                  }
                  if (button.type === "select") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].options.map((o) => {
                      o.choices = [[]];
                    });
                  }
                  console.log(r);
                  notification("Поле опций добавлено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                Добавить опции для описания
              </button>
            )}

          {Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choices"
          ) && (
            <button
              onClick={(e) => {
                let r = { ...rybaObject };
                let button =
                  r.organs[organINDEX.current].segments[segmentINDEX.current]
                    .buttons[buttonINDEX.current];
                if (button.type === "checkbox") {
                  delete r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choices;
                }
                if (button.type === "radio") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios.map((r) => {
                    delete r.choices;
                  });
                }
                if (button.type === "select") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options.map((o) => {
                    delete o.choices;
                  });
                }
                delete r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].choices;
                notification("Поле опций удалено");
                setRybaObject(r);
                setButtonContextMenu(false);
              }}
            >
              Удалить опции для описания
            </button>
          )}
          {!Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choicesZakl"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type !== "constructor" && (
              <button
                onClick={(e) => {
                  let r = { ...rybaObject };
                  const button =
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current];

                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choicesZakl = [[]];

                  if (button.type === "radio") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].radios.map((r) => {
                      r.choicesZakl = [[]];
                    });
                  }
                  if (button.type === "select") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].options.map((o) => {
                      o.choicesZakl = [[]];
                    });
                  }
                  console.log(r);
                  notification("Поле опций добавлено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                Добавить опции для заключения
              </button>
            )}

          {Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choicesZakl"
          ) && (
            <button
              onClick={(e) => {
                let r = { ...rybaObject };
                let button =
                  r.organs[organINDEX.current].segments[segmentINDEX.current]
                    .buttons[buttonINDEX.current];
                if (button.type === "checkbox") {
                  delete r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choicesZakl;
                }
                if (button.type === "radio") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios.map((r) => {
                    delete r.choicesZakl;
                  });
                }
                if (button.type === "select") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options.map((o) => {
                    delete o.choicesZakl;
                  });
                }
                delete r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].choicesZakl;
                notification("Поле опций удалено");
                setRybaObject(r);
                setButtonContextMenu(false);
              }}
            >
              Удалить опции для заключения
            </button>
          )}
          {!Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "images"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type !== "constructor" && (
              <button
                onClick={(e) => {
                  let r = { ...rybaObject };

                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].images = [];
                  notification("Поле изображений добавлено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                Добавить изображения
              </button>
            )}
          {Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "images"
          ) && (
            <button
              onClick={(e) => {
                let r = { ...rybaObject };

                delete r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].images;
                notification("Поле изображений удалено");
                setRybaObject(r);
                setButtonContextMenu(false);
              }}
            >
              Удалить изображения
            </button>
          )}
          <button
            className="redOnHover"
            onClick={(e) => {
              deleteButtonRequest(
                e,
                buttonINDEX.current,
                segmentINDEX.current,
                organINDEX.current
              );
              setButtonContextMenu(false);
              notification("Кнопка удалена");
            }}
          >
            Удалить
          </button>
          <button onClick={() => setButtonContextMenu(false)}>Закрыть</button>
        </div>
      )}
      {rybaObject && modalityState && (
        <div>
          <div className="topButtons" key={(keyCounter += 1)}>
            <div className="titleEditing">
              <TextareaAutosize
                value={rybaObject.title}
                onChange={(e) => {
                  console.log(e.target.value);
                  let r = { ...rybaObject };
                  r.title = e.target.value;
                  setRybaObject(r);
                }}
              ></TextareaAutosize>
            </div>
            <div className="saveButtonsDiv">
              <button
                style={{
                  backgroundColor: rybaObject === originalRyba && "grey",
                }}
                className="bigSaveButton"
                onClick={() => {
                  setSaving(true);
                  // window.localStorage.removeItem(id);
                  saveRybaChanges();

                  setOriginalRyba(rybaObject);
                  if (localStorageTXTtemp) {
                    window.localStorage.setItem(
                      `${id}`,
                      JSON.stringify(localStorageTXTtemp)
                    );
                  }

                  // setTimeout(() => {
                  //   navigate(`/primary/${id}`);
                  // }, 1000);
                }}
              >
                {!saving && "СОХРАНИТЬ"}
                {saving && "Сохраняется..."}
              </button>

              {rybaObject === originalRyba && (
                <button
                  className="bigNOTSaveButton"
                  disabled={saving}
                  onClick={() => {
                    // window.localStorage.removeItem(id);
                    setRybaObject(undefined);
                    navigate(`/primary/${id}`);
                  }}
                >
                  Закрыть редактор и вернуться к шаблону
                </button>
              )}
              {rybaObject !== originalRyba && (
                <button
                  className="bigNOTSaveButton"
                  onClick={() => {
                    window.localStorage.removeItem(id);
                    setRybaObject(undefined);
                    navigate(`/primary/${id}`);
                  }}
                >
                  Закрыть без сохранения (данные будут потеряны)
                </button>
              )}
            </div>

            <div className="rybaModalityProtocolShareDiv">
              <div>
                <label>
                  <span>Протокол</span>

                  {modalityState === "КТ" && (
                    <select
                      value={rybaObject.protocol}
                      className="protocolSelectSELECT"
                      onChange={(e) => {
                        let r = { ...rybaObject };
                        r.protocol = e.target.value;
                        setRybaObject(r);
                      }}
                      name="selectProtocol"
                      id="2"
                      style={{
                        width: "200px",
                      }}
                    >
                      <option value="">Выберите протокол</option>
                      {protocolsCT.map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  )}
                  {modalityState === "МРТ" && (
                    <select
                      className="protocolSelectSELECT"
                      onChange={(e) => {
                        let r = { ...rybaObject };
                        r.protocol = e.target.value;
                        setRybaObject(r);
                      }}
                      name="selectProtocol"
                      id="3"
                    >
                      <option value="">Выберите протокол</option>
                      {protocolsMRI.map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  )}
                </label>
              </div>
              <div>
                <label>
                  Модальность
                  <select
                    value={rybaObject.modality}
                    className="protocolSelectSELECT"
                    onChange={(e) => {
                      let r = { ...rybaObject };
                      r.modality = e.target.value;
                      setRybaObject(r);
                    }}
                    name="selectProtocol"
                    id="2"
                  >
                    <option value="">Выберите модальность</option>
                    <option value="КТ">КТ</option>
                    <option value="МРТ">МРТ</option>
                    <option value="Классика">Классика</option>
                    <option value="УЗИ">УЗИ</option>
                    <option value="Маммография">Маммография</option>
                    <option value="ПЭТ">ПЭТ</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  Поделиться шаблоном
                  <select
                    value={rybaObject.shared}
                    onChange={(e) => {
                      let r = { ...rybaObject };
                      r.shared = e.target.value;
                      setRybaObject(r);
                    }}
                    className="protocolSelectSELECT"
                  >
                    <option value={false}>Нет</option>
                    <option value={true}>Да</option>
                  </select>
                </label>
              </div>
            </div>
            {/* {!isRybaShared && (
              <>
                <p className="shareRybaP">
                  Этот шаблон приватный. Хотите поделиться шаблоном с коллегами
                  в общий доступ?
                </p>
                <button onClick={shareRyba} className="shareRybaButton">
                  Поделиться
                </button>
              </>
            )}
            {isRybaShared && (
              <>
                <p className="shareRybaP">
                  Спасибо, что поделились свои шаблоном! Коллеги будут Вам
                  благодарны.
                </p>
                <button onClick={hideRyba} className="shareRybaButton">
                  Снова сделать шаблон приватным
                </button>
              </>
            )} */}
            <div className="imagesUploadDiv">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className="RYBA4topButton"
                  onClick={(e) => {
                    window.localStorage.setItem(
                      `zoomRedactor`,
                      JSON.stringify(zoom + 0.05)
                    );
                    setZoom((z) => z + 0.05);
                  }}
                  data-tooltip-id="ZoomIn"
                  data-tooltip-delay-show="300"
                >
                  <ZoomIn />
                  <Tooltip
                    id="ZoomIn"
                    content="Увеличить мастштаб (приблизить)"
                    place="bottom"
                    style={{ background: "black" }}
                  />
                </div>

                <div
                  className="RYBA4topButton"
                  onClick={(e) => {
                    window.localStorage.setItem(
                      `zoom`,
                      JSON.stringify(zoom - 0.05)
                    );
                    setZoom((z) => z - 0.05);
                  }}
                  data-tooltip-id="ZoomOut"
                  data-tooltip-delay-show="300"
                >
                  <ZoomOut />
                  <Tooltip
                    id="ZoomOut"
                    content="Уменьшить масштаб (отдалить)"
                    place="bottom"
                    style={{ background: "black" }}
                  />
                </div>
              </div>

              <button
                onClick={() => {
                  setRightImagesRedactor(true);
                }}
              >
                Открыть редактор шпаргалки
              </button>
            </div>
            <button
              onClick={() => {
                setAreYouSureOpen(true);
              }}
              className="shareRybaButton"
            >
              Удалить шаблон
            </button>
          </div>

          <div className="rooDivEdit">
            <div className="rybaRedactorNew" key={(keyCounter += 1)}>
              <>
                {notificationWindowOpen && (
                  <Notification notificationText={notificationText.current} />
                )}

                <div className="RYBA4segmentsContainerEditRIGHT">
                  {/* <p
                    style={{
                      margin: "0px",
                      textAlign: "left",
                      paddingLeft: "14px",
                      fontSize: "22px",
                      fontWeight: "100",
                      color: "darkgrey",
                    }}
                  >
                    Органы
                  </p>
                  <hr /> */}
                  {rybaObject.organs.map((organ, organIndex) =>
                    editedOrgan === organIndex ? (
                      <div className="organEdit">
                        <div
                          className="organTitleEdit"
                          onContextMenu={(e) => {
                            e.preventDefault();
                            if (
                              organIndex < 2 ||
                              organIndex === rybaObject.organs.length - 1
                            ) {
                              return;
                            }
                            organINDEX.current = organIndex;
                            setEditedOrgan(organIndex);
                            setEditedSegment(0);
                            setLeftOrganContextMenu(e.pageX);
                            setTopOrganContextMenu(e.pageY);
                            setOrganContextMenu(true);
                          }}
                        >
                          <TextareaAutosize
                            className={`organTitleTextarea ${
                              editedOrgan === organIndex
                                ? "whiteBorder"
                                : "notWhiteBorder"
                            }`}
                            autoFocus
                            value={rybaObject.organs[organIndex].title}
                            onChange={(e) => {
                              console.log(e.target.value);
                              let r = { ...rybaObject };
                              r.organs[organIndex].title =
                                e.target.value.toUpperCase();
                              setRybaObject(r);
                            }}
                          ></TextareaAutosize>
                        </div>
                      </div>
                    ) : (
                      <div className="organTitleEdit">
                        <TextareaAutosize
                          className="organTitleTextareaNOTEDITED"
                          value={rybaObject.organs[organIndex].title}
                          onFocus={(e) => {
                            setEditedOrgan(organIndex);
                            setEditedSegment(0);
                          }}
                          onChange={(e) => {
                            console.log(e.target.value);
                            let r = { ...rybaObject };
                            r.organs[organIndex].title =
                              e.target.value.toUpperCase();
                            setRybaObject(r);
                          }}
                        ></TextareaAutosize>
                      </div>
                      //   <div className="notEditedSegment">
                      //   <p
                      //     style={{
                      //       textAlign: "center",
                      //     }}
                      //     onClick={(e) => setEditedOrgan(organIndex)}
                      //   >
                      //     {organ.title}
                      //   </p>
                      // </div>
                    )
                  )}

                  <div className="topButtonsRedactor" key={(keyCounter += 1)}>
                    <button
                      className="addNewOrgan"
                      onClick={() => {
                        createOrgan();
                      }}
                    >
                      ➕ Создать новый орган
                    </button>
                  </div>
                </div>
              </>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="forSummary">
                <div className="finalOrganText">
                  <p>
                    {editedOrgan !== 0 &&
                      `${rybaObject.organs[editedOrgan].title}: `}
                    {rybaObject.organs[editedOrgan].segments.map((s) => (
                      <span>{s.norm}</span>
                    ))}
                  </p>
                </div>
              </div>

              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className="rybaRedactorNew"
                    key={(keyCounter += 1)}
                    style={{
                      padding: "0px",
                      width: "220px",
                    }}
                  >
                    <>
                      {notificationWindowOpen && (
                        <Notification
                          notificationText={notificationText.current}
                        />
                      )}

                      <div className="RYBA4segmentsContainerEditRIGHT">
                        {/* <p
                          style={{
                            margin: "0px",
                            textAlign: "left",
                            paddingLeft: "14px",
                            fontSize: "19px",
                            fontWeight: "100",
                            color: "darkgrey",
                          }}
                        >
                          Разделы
                        </p>
                        <hr
                          style={{
                            border: "0.5px solid grey",
                            width: "94%",
                          }}
                        /> */}
                        {rybaObject.organs[editedOrgan].segments &&
                          rybaObject.organs[editedOrgan].segments.length ===
                            0 && (
                            <>
                              <p>Разделов нет. Нажмите "добавить раздел"</p>
                            </>
                          )}
                        {rybaObject.organs[editedOrgan].segments.map(
                          (segment, segmentIndex) =>
                            editedSegment === segmentIndex ? (
                              <div className="organEdit">
                                <div
                                  className="organTitleEdit"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                    organINDEX.current = editedOrgan;
                                    segmentINDEX.current = segmentIndex;
                                    setEditedSegment(segmentIndex);
                                    setLeftSegmentContextMenu(e.pageX);
                                    setTopSegmentContextMenu(e.pageY);
                                    setSegmentContextMenu(true);
                                  }}
                                >
                                  <TextareaAutosize
                                    className={`organTitleTextarea ${
                                      editedSegment === segmentIndex
                                        ? "whiteBorder"
                                        : "notWhiteBorder"
                                    }`}
                                    autoFocus
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "100",
                                    }}
                                    value={
                                      rybaObject.organs[editedOrgan].segments[
                                        editedSegment
                                      ].title
                                    }
                                    onChange={(e) => {
                                      if (
                                        editedOrgan ===
                                        rybaObject.organs.length - 1
                                      ) {
                                        return;
                                      }
                                      console.log(e.target.value);
                                      let r = { ...rybaObject };
                                      r.organs[editedOrgan].segments[
                                        editedSegment
                                      ].title = e.target.value;
                                      setRybaObject(r);
                                    }}
                                  ></TextareaAutosize>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="organTitleEdit"
                                onContextMenu={(e) => {
                                  e.preventDefault();

                                  organINDEX.current = editedOrgan;
                                  segmentINDEX.current = segmentIndex;
                                  setEditedSegment(segmentIndex);
                                  setLeftSegmentContextMenu(e.pageX);
                                  setTopSegmentContextMenu(e.pageY);
                                  setSegmentContextMenu(true);
                                }}
                              >
                                <TextareaAutosize
                                  className="organTitleTextareaNOTEDITED"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "100",
                                  }}
                                  value={
                                    rybaObject.organs[editedOrgan].segments[
                                      segmentIndex
                                    ].title
                                  }
                                  onFocus={(e) =>
                                    setEditedSegment(segmentIndex)
                                  }
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    let r = { ...rybaObject };
                                    r.organs[editedOrgan].segments[
                                      editedSegment
                                    ].title = e.target.value;
                                    setRybaObject(r);
                                  }}
                                ></TextareaAutosize>
                              </div>
                              //   <div className="notEditedSegment">
                              //   <p
                              //     style={{
                              //       textAlign: "center",
                              //     }}
                              //     onClick={(e) => setEditedOrgan(organIndex)}
                              //   >
                              //     {organ.title}
                              //   </p>
                              // </div>
                            )
                        )}

                        {rybaObject.organs.length - 1 !== editedOrgan && (
                          <div
                            className="topButtonsRedactor"
                            key={(keyCounter += 1)}
                          >
                            <button
                              className="addNewOrgan"
                              onClick={(e) => {
                                organINDEX.current = editedOrgan;
                                createNewSegment();
                                setEditedSegment(
                                  (o) =>
                                    (o =
                                      rybaObject.organs[editedOrgan].segments
                                        .length - 1)
                                );
                              }}
                              style={{
                                width: "205px",
                                fontSize: "15px",
                              }}
                            >
                              ➕ Создать новый раздел
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  </div>

                  <div className="organRedactor">
                    <div className="RYBA4segmentsContainerEdit">
                      {rybaObject.organs.map(
                        (organ, organIndex) =>
                          editedOrgan === organIndex && (
                            <div className="organEdit">
                              <div className="organSegmentsEdit">
                                {organ.segments &&
                                  organ.segments.map(
                                    (segment, segmentIndex) =>
                                      segmentIndex === editedSegment && (
                                        <div
                                          className="sgmntEdit"
                                          key={(keyCounter += 1)}
                                          index={segmentIndex}
                                        >
                                          <div className="segmentTitleDivEdit">
                                            <div
                                              style={{
                                                width: "100%",
                                                paddingTop: "8px",
                                              }}
                                            >
                                              <TextareaAutosize
                                                className="segmentTextTextareaEdit"
                                                placeholder="-"
                                                value={
                                                  rybaObject.organs[organIndex]
                                                    .segments[segmentIndex].norm
                                                }
                                                onChange={(e) => {
                                                  const r = { ...rybaObject };
                                                  r.organs[organIndex].segments[
                                                    segmentIndex
                                                  ].norm = e.target.value;
                                                  setRybaObject(r);
                                                }}
                                              ></TextareaAutosize>
                                            </div>
                                          </div>

                                          <div className="segmentTextEdit">
                                            {segment.buttons.length !== 0 && (
                                              <div
                                                className="segmentButtonsEdit"
                                                key={
                                                  rybaObject.organs[organIndex]
                                                    .segments
                                                }
                                              >
                                                {segment.buttons.map(
                                                  (button, buttonIndex) => (
                                                    <ButtonsRedactor
                                                      {...{
                                                        segment,
                                                        button,
                                                        rybaObject,
                                                        organIndex,
                                                        segmentIndex,
                                                        buttonIndex,
                                                        setRybaObject,
                                                        deleteButtonRequest,
                                                        locateButton,
                                                        duplicateButton,
                                                        moveButtonUpward,
                                                        moveButtonDownward,
                                                        notification,
                                                        contextMenuButton,
                                                        buttonContextMenu,
                                                        contextHighlight,
                                                        setContextHighlight,
                                                      }}
                                                    />
                                                  )
                                                )}
                                                <div className="addButtonsDiv">
                                                  <p
                                                    style={{
                                                      paddingRight: "7px",
                                                      color: "lightgrey",
                                                    }}
                                                  >
                                                    Добавить кнопки
                                                  </p>

                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewCheckbox();
                                                    }}
                                                    data-tooltip-id="checkbox"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    <CheckboxsVG
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="checkbox"
                                                      content="Добавить простую кнопку"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Создать кнопку чекбокс */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewRadio();
                                                    }}
                                                    data-tooltip-id="radio"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    <RadioSVG
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="radio"
                                                      content="Добавить группу кнопок (кнопки, обведённые в рамку с названием; поможет визуально связать общие по смыслу штуки)"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Создать радиокнопку */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewSelect();
                                                    }}
                                                    data-tooltip-id="dropdown"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    {" "}
                                                    <Dropdown
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="dropdown"
                                                      content="Добавить меню (кнопки, сгруппированные в выпадающее меню для компактности)"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Создать селект */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewConstructor();
                                                    }}
                                                    data-tooltip-id="constructor"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    {" "}
                                                    <ConstructorSVG
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="constructor"
                                                      content="Добавить конструктор или калькулятор из списка (уже готовые кнопки с рашсиренным функционалом)"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Добавить конструктор */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      let r = {
                                                        ...rybaObject,
                                                      };
                                                      const length =
                                                        r.organs[organIndex]
                                                          .segments[
                                                          segmentIndex
                                                        ].buttons.length;
                                                      r.organs[
                                                        organIndex
                                                      ].segments[
                                                        segmentIndex
                                                      ].buttons.splice(
                                                        length,
                                                        0,
                                                        JSON.parse(
                                                          window.localStorage.getItem(
                                                            "copiedBUTTON"
                                                          )
                                                        )
                                                      );
                                                      setRybaObject(r);
                                                      notification(
                                                        "Кнопка из буфера обмена добавлена"
                                                      );
                                                    }}
                                                    data-tooltip-id="dcsdcs"
                                                    data-tooltip-delay-show="300"
                                                    style={{
                                                      width: "120px",
                                                    }}
                                                  >
                                                    <Tooltip
                                                      id="dcsdcs"
                                                      content="Вставит ранее скопированную кнопку"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    Вставить скопированную
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                            {segment.buttons.length === 0 && (
                                              <div className="segmentButtonsEdit">
                                                <span
                                                  style={{
                                                    textAlign: "center",
                                                    marginLeft: "90px",
                                                    color: "grey",
                                                  }}
                                                >
                                                  Кнопок ещё нет
                                                </span>
                                                <div className="addButtonsDiv">
                                                  <p
                                                    style={{
                                                      paddingRight: "7px",
                                                      color: "lightgrey",
                                                    }}
                                                  >
                                                    Добавить кнопки
                                                  </p>

                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewCheckbox();
                                                    }}
                                                    data-tooltip-id="checkbox"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    <CheckboxsVG
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="checkbox"
                                                      content="Добавить простую кнопку"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewRadio();
                                                    }}
                                                    data-tooltip-id="radio"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    <RadioSVG
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="radio"
                                                      content="Добавить группу кнопок (кнопки, обведённые в рамку с названием; поможет визуально связать общие по смыслу штуки)"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Создать радиокнопку */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewSelect();
                                                    }}
                                                    data-tooltip-id="dropdown"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    {" "}
                                                    <Dropdown
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="dropdown"
                                                      content="Добавить меню (кнопки, сгруппированные в выпадающее меню для компактности"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Создать селект */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      organINDEX.current =
                                                        organIndex;
                                                      createNewConstructor();
                                                    }}
                                                    data-tooltip-id="constructor"
                                                    data-tooltip-delay-show="300"
                                                  >
                                                    {" "}
                                                    <ConstructorSVG
                                                      width={32}
                                                      height={32}
                                                      color={"black"}
                                                    />
                                                    <Tooltip
                                                      id="constructor"
                                                      content="Добавить конструктор или калькулятор из списка (уже готовые кнопки с рашсиренным функционалом)"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    {/* Добавить конструктор */}
                                                  </button>
                                                  <button
                                                    className="segmentButtonEdit"
                                                    onClick={() => {
                                                      let r = {
                                                        ...rybaObject,
                                                      };
                                                      const length =
                                                        r.organs[organIndex]
                                                          .segments[
                                                          segmentIndex
                                                        ].buttons.length;
                                                      r.organs[
                                                        organIndex
                                                      ].segments[
                                                        segmentIndex
                                                      ].buttons.push(
                                                        JSON.parse(
                                                          window.localStorage.getItem(
                                                            "copiedBUTTON"
                                                          )
                                                        )
                                                      );
                                                      setRybaObject(r);
                                                      notification(
                                                        "Кнопка из буфера обмена добавлена"
                                                      );
                                                    }}
                                                    data-tooltip-id="dcsdcs"
                                                    data-tooltip-delay-show="300"
                                                    style={{
                                                      width: "120px",
                                                    }}
                                                  >
                                                    <Tooltip
                                                      id="dcsdcs"
                                                      content="Вставит ранее скопированную кнопку"
                                                      place="bottom"
                                                      style={{
                                                        background: "black",
                                                        zIndex: "90",
                                                      }}
                                                    />
                                                    Вставить скопированную
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )
                                  )}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ryba;
