import { useEffect, useState, useRef } from "react";
import InputPrompt from "./editing/InputPrompt";
import { useRecoilState } from "recoil";
import { textState } from "../state/atoms/textareaText";
import { promptStateAtom } from "../state/atoms/promptState";
import { formStateAtom } from "../state/atoms/formState";
import { fullRYBAatom } from "../state/atoms/fullRYBAatom";
import { zaklStateAtom } from "../state/atoms/zaklState";
import { useParams } from "react-router-dom";
import { rybaIDatom } from "../state/atoms/rybaID";
import Notification from "./editing/NotificationWindow";
import "./Ryba4.css";
import "./Ryba5tutorial.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import CopyAll from "./svg/CopyAll";
import ResetToNorm from "./svg/ResetToNorm";
import EditRyba from "./svg/EditRyba";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import React from "react";
import ConstructorRouter from "./smallComponents/constructorRouter";
import CopyProtocol from "./svg/CopyProtocol";
import CopyImpression from "./svg/CopyImpression";
import TextareaAutosize from "react-textarea-autosize";
import Buttons from "../components/smallComponents/buttons";
import ZoomIn from "./svg/ZoomIn";
import ZoomOut from "./svg/ZoomOut";
import SaveButton from "./svg/SaveButton";
import TXT from "./svg/TXT";
import { saveAs } from "file-saver";
import DOC from "./svg/DOC";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import OK from "./svg/OK";
import Textarea from "./rybaComponents/textarea";
import { settingsAtom } from "../state/atoms/settings";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import SadFish from "./images/SadFish.png";
import Shape from "./shapes/shapes";
import Shape2 from "./shapes/shapes2";
import Brain from "./shapes/Nuclei.png";
import ImageComponent from "./smallComponents/imageComponent";
import OverlayImages from "./smallComponents/OverlayImages";
import InputPromptZakl from "./editing/InputPromptZakl";
import rybaForTutorial from "../data/tutorialRyba";
import settingsTutorial from "../data/settings";
import Arrow from "./svg/Arrow";
import ArrowComponent from "./Arrow";
import TextareaTutorial from "./rybaComponents/textareaTutorial";
import ButtonsTutorial from "./smallComponents/buttonsTutorial";
import InputPromptTutorial from "./editing/InputPromptTutorial";
import InputPromptZaklTutorial from "./editing/InputPromptZaklTutorial";

const RybaTutorial = () => {
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom);
  const [text, setText] = useRecoilState(textState); // recoil state of the textarea
  // const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom);
  // const [text, setText] = useRecoilState(textState);
  const [promptText, setPromptText] = useRecoilState(promptStateAtom);
  const [formState, setFormState] = useRecoilState(formStateAtom);
  const [zaklState, setZaklState] = useRecoilState(zaklStateAtom);
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const { id } = "tutorial";
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const [inputPromptOpen, setInputPromptOpen] = useState(false);
  const [inputPromptZaklOpen, setInputPromptZaklOpen] = useState(false);
  const [rated, setRated] = useState(true);
  // const { user } = useAuthContext();
  ////////// Ryba4 state ////////
  const [organFocus, setOrganFocus] = useState(2000);
  const [rybaLoaded, setRybaLoaded] = useState(false);
  const [reset, setReset] = useState(false);
  const [constructorOpen, setConstructorOpen] = useState(false);
  const [printData, setPrintData] = useState({});
  const [dinamika, setDinamika] = useState(false);
  const [notes, setNotes] = useState();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(-360);
  const [pressed, setPressed] = useState(false);
  const [nameReportOpen, setNameReportOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportTitle2, setReportTitle2] = useState(null);
  const [saved, setSaved] = useState(false);
  const [beenModified, setBeenModified] = useState(false);
  const [error, setError] = useState("");
  const [zoom, setZoom] = useState();
  const [waitedForLoading, setWaitedForLoading] = useState(false);
  const [choicesCount, setChoicesCount] = useState(0);
  const [promptButton, setPromptButton] = useState();
  const [overlayImages, setOverlayImages] = useState(false);
  const [typeSpeed, setTypeSpeed] = useState(0);

  // const [buffer, setBuffer, undo, redo] = useUndoRedo([], 100);
  // const [buffer2, setBuffer2, undo2, redo2] = useUndoRedo(undefined, 100);
  ///////////////////// TUTORIAL STEPS //////////////////////

  const [disRyba, setDisRyba] = useState(true);
  const [tut1, setTut1] = useState(false);
  const [tut2, setTut2] = useState(false);
  const [tut3, setTut3] = useState(false);
  const [tut4, setTut4] = useState(false);
  const [tut5, setTut5] = useState(false);
  const [tut6, setTut6] = useState(false);
  const [tut7, setTut7] = useState(false);
  const [tut8, setTut8] = useState(false);
  const [tut9, setTut9] = useState(false);
  const [tut10, setTut10] = useState(false);
  const [tut11, setTut11] = useState(false);
  const [tut12, setTut12] = useState(false);
  const [tut13, setTut13] = useState(false);
  const [tut14, setTut14] = useState(false);
  const [tut15, setTut15] = useState(false);
  const [tut16, setTut16] = useState(false);
  const [tut17, setTut17] = useState(false);
  const [tut18, setTut18] = useState(false);
  const [tut19, setTut19] = useState(false);
  const [tut20, setTut20] = useState(false);
  const [tut21, setTut21] = useState(false);
  const [tut22, setTut22] = useState(false);
  const [tut23, setTut23] = useState(false);
  const [tut24, setTut24] = useState(false);

  useEffect(() => {
    const getFullRyba = async (req, res) => {
      const json = rybaForTutorial;

      // setting up ryba obj
      let r = { ...json };
      // console.log("rybaObject: ", r);
      const localStorageTXT = JSON.parse(localStorage.getItem(id));
      const z = JSON.parse(localStorage.getItem("zoom"));
      setZoom(z);
      if (!z) {
        setZoom(1);
      }
      const notes = JSON.parse(localStorage.getItem("notes" + id));
      // console.log("длина рыбы совпадает?", r.organs, localStorageTXT);
      if (1) {
        console.log("rybaObject: ", r);
        setRybaObject(r);

        setRybaLoaded(true);
        // set text state
        if (notes) {
          setNotes(notes);
        }
        if (!localStorageTXT) {
          // set raw text
          let t = [];
          let c = [];
          r.organs.map((o, organIndex) => {
            t.push([]);
            c.push([]);
            o.segments.map((s, segmentIndex) => {
              t[organIndex].push(s.norm);
              c[organIndex].push([]);
              s.buttons.map((b) => {
                c[organIndex][segmentIndex].push(false);
              });
            });
          });
          setText(t);
          setReset(c);

          let h = [];

          r.organs.map((o, organIndex) => {
            h.push([]);
            o.segments.map((s, segmentIndex) => {
              h[organIndex].push([]);
              if (s.buttons) {
                s.buttons.map((b, buttonIndex) => {
                  h[organIndex][segmentIndex].push("");
                });
              }
            });
          });
        }

        if (localStorageTXT) {
          if (localStorageTXT.length !== r.organs.length) {
            setBeenModified(true);
          }
          let t = [];
          r.organs.map((o, organIndex) => {
            t.push([]);

            o.segments.map((s, segmentIndex) => {
              if (localStorageTXT[organIndex] !== undefined || null) {
                localStorageTXT[organIndex]
                  ? t[organIndex].push(
                      localStorageTXT[organIndex][segmentIndex]
                    )
                  : t[organIndex].push(s.norm);
                if (o.segments.length !== localStorageTXT[organIndex].length) {
                  setBeenModified(true);
                }
              }
            });
          });
          setText(t);
          // setText(localStorageTXT);
          let c = [];
          r.organs.map((o, organIndex) => {
            c.push([]);
            o.segments.map((s, segmentIndex) => {
              c[organIndex].push([]);
              s.buttons.map((b) => {
                c[organIndex][segmentIndex].push(false);
              });
            });
          });
          setReset(c);
        }
      }
    };

    const stngs = settingsTutorial;

    setSettings(stngs);
    let p = {};
    p.hospital = stngs.rybaHeaders[0];
    setPrintData(p);

    getFullRyba();

    setTimeout(() => {
      setWaitedForLoading(true);
      console.log("I've waited for loading");
    }, 3000);
  }, []);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;
  let zaklRef = useRef("");
  let notificationText = useRef();
  let segmentNumber = useRef();
  let organNumber = useRef();
  let whichClick = useRef();
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  let constructorREF = useRef({});
  let imagesREF = useRef();

  // notification function
  const notification = function (message) {
    notificationText.current = message;

    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1100);
  };
  ///////// DATE AND TIME FUNCTIONS ///////////
  const createDate = () => {
    const currentdate = new Date();
    const day = `${
      currentdate.getDate() > 9
        ? currentdate.getDate()
        : "0" + currentdate.getDate()
    }`;
    const month = `${
      currentdate.getMonth() + 1 > 9
        ? currentdate.getMonth() + 1
        : "0" + Number(currentdate.getMonth() + 1)
    }`;
    const year = `${currentdate.getFullYear()}`;
    return `${day}.${month}.${year}`;
  };
  const createTime = () => {
    const currentdate = new Date();
    const minutes = `${
      currentdate.getMinutes() > 9
        ? currentdate.getMinutes()
        : "0" + currentdate.getMinutes()
    }`;
    const hours = `${
      currentdate.getHours() > 9
        ? currentdate.getHours()
        : "0" + currentdate.getHours()
    }`;
    return `${hours}:${minutes}`;
  };

  // set input prompt value
  const setPromptInputValue = () => {
    if (tut12 === true) {
      setTut12(false);
      setTut13(true);
      setOrganFocus(2);
    }
    let q = [...promptText];
    const i = q[0] + q[1] + q[2];
    if (i.includes("...")) {
      setChoicesCount((x) => (x += 1));
      setInputPromptOpen(true);
      let g = [];
      let y = i;
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }

    let t = [...text];
    ///////
    t[organINDEX.current][segmentINDEX.current] += rybaObject.organs[
      organINDEX.current
    ].segments[segmentINDEX.current].buttons[buttonINDEX.current].newline
      ? "\n" + i
      : i;
    t[organINDEX.current][segmentINDEX.current] =
      t[organINDEX.current][segmentINDEX.current].trim();
    setText(t);

    setInputPromptOpen(false);
    console.log("promptButton passed in prompt: ", promptButton);
    checkForDotsZakl(promptButton.zakl, promptButton);
  };

  // BUTTON copy text from ALL textareas
  const clickHandlerCopyToClipboardALL = function (e) {
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    navigator.clipboard.writeText(rybaString);
    notification("Текст протокола успешно скопирован🔥");
  };

  const clickHandlerCopyToClipboardProtocol = function (e) {
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.splice(-1, 1);
    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
    });

    navigator.clipboard.writeText(rybaString.trim());
    notification("Текст протокола успешно скопирован🔥");
  };

  const clickHandlerCopyToClipboardImpression = function (e) {
    const x = [...text];
    const orgns = [...rybaObject.organs];

    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        return;
      }
      if (organIndex > 0 && organIndex < rybaObject.organs.length - 1) {
        return;
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString = organTextString;
      }
    });

    navigator.clipboard.writeText(rybaString.trim());
    notification("Текст заключения успешно скопирован🔥");
  };

  ///////
  const rateButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  ////////////////////////

  const buttonClick = (
    e,
    organIndex,
    segmentIndex,
    buttonIndex,
    zakl,
    buttonForChoices
  ) => {
    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    if (tut14 === true) {
      setTut14(false);
      setTut15(true);
    }
    if (tut8 === true) {
      setTut8(false);
      setTut9(true);
    }
    if (
      tut11 === true &&
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
        .title === "Гидроперикард"
    ) {
      setTut11(false);
      setTut12(true);
    }
    if (
      e.target.checked === true &&
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
        .type === "constructor"
    ) {
      return;
    }

    const rybaLength = rybaObject.organs.length - 1;
    const btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];

    if (
      t[rybaLength][0] &&
      !t[rybaLength][0].includes(zakl) &&
      zakl !== undefined &&
      zakl !== "" &&
      !zakl.includes("...")
    ) {
      if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
        t[rybaLength][0] = "";
      }
      t[rybaLength][0] = t[rybaLength][0].trim() + " " + zakl.trim();
      t[rybaLength][0] = t[rybaLength][0].trim();
    }

    checkForDots(e.target.value, buttonForChoices);

    if (e.target.value.includes("...")) return;
    if (zakl !== undefined && zakl !== "") {
      checkForDotsZakl(zakl, buttonForChoices);
    }

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim() + " ";
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + e.target.value.trim()
      : e.target.value.trim();
    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();

    setText(t);
    if (tut5 === true) {
      setTut5(false);
      setTut6(true);
      setOrganFocus(6);
    }
    if (tut6 === true) {
      setTut6(false);
      setTut7(true);
      setOrganFocus(2000);
    }

    // let b = [...undoBuffer];

    // b.splice(0, 0, t);
    // setUndoBuffer(b);
    // undoCounter.current = 0;
    // console.log(b);
  };

  // BUTTON adding text to the textarea
  const checkForDots = function (bTextContent, button) {
    let y = bTextContent;
    console.log("check for dots Y: ", y);

    if (y.includes("...")) {
      setChoicesCount(0);
      setPromptButton(button);
      setInputPromptOpen(true);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };
  const checkForDotsZakl = function (zaklText, button) {
    let y = zaklText;
    console.log("check for dots ZAKL: ", y);

    if (y.includes("...")) {
      setChoicesCount(0);
      setPromptButton(button);
      setInputPromptZaklOpen(true);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };

  const rL = function (rO) {
    return rO.organs.length - 1;
  };

  // dinamika constructor function
  const dinamikaConstructorFunction = function (opisanie, zakl) {
    navigator.clipboard.writeText(opisanie);
    notification("Текст для описания скопирован");
    let t = `${text}`;
    if (!t.includes(zakl) && zakl) {
      t += zakl;
    }
  };

  const rateColor = [
    "red",
    "red",
    "orange",
    "orange",
    "yellow",
    "yellow",
    "lightgreen",
    "lightgreen",
    "green",
    "green",
  ];

  const exportTXT = () => {
    ////////// CREATE REPORT ///////
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";
    rybaString += "Модальность: " + rybaObject.modality + "\n";
    rybaString += "Исследование: " + rybaObject.protocol + "\n";

    rybaString += "Дата: " + createDate() + "\n\n";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    ////////////////////////////////
    // const fileData = JSON.stringify(rybaString);
    const file = new Blob([rybaString], { type: "text/plain;charset=utf-8" });
    saveAs(file, `${rybaObject.protocol} ${createDate()}.txt`);
  };

  const exportWORD = async () => {
    ////////// CREATE REPORT ///////
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });
    /////// CREATE DOCX //////

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: "12pt",
              font: "Arial",
            },
          },
        },
      },
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: settings.rybaHeaders[0]
                .split("\n")
                .map((line) => new TextRun({ break: 1, text: line })),
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "ФИО: ",
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Дата рождения: ",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Пол: ",
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: rybaObject.protocol,
                  bold: true,
                  size: "16pt",
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),

            new Paragraph({}),
            new Paragraph({
              children: rybaString
                .split("\n")
                .map(
                  (line) => new TextRun({ break: 1, text: line, size: "11pt" })
                ),
            }),

            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Врач-рентгенолог",
                  alignment: AlignmentType.LEFT,
                }),
                new TextRun({}),
              ],
            }),
            new Paragraph({
              text: `${settings.lastname} ${settings.firstname.slice(
                0,
                1
              )}. ${settings.middlename.slice(0, 1)}.`,
              alignment: AlignmentType.RIGHT,
            }),
            new Paragraph({
              text: `Дата: ${createDate()}`,
            }),
          ],
        },
      ],
    });
    //////////////////////////
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, `${rybaObject.protocol} ${createDate()}.docx`);
    });

    ////////////////////////////////
    // const file = new Blob([doc], { type: "text/plain;charset=utf-8" });
    // saveAs(file, `${rybaObject.protocol} ${date}.docx`);
  };

  const updateLocalStorage = useDebouncedCallback((text) => {
    window.localStorage.setItem(`${id}`, JSON.stringify(text));
  }, 400);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
    if (waitedForLoading === true) {
      updateLocalStorage(text);
    }
  }, [text]);

  // BUTTON go to default ALL
  const goToDefaultAll = (e) => {
    setSaved(false);
    const rybaLength = rybaObject.organs.length - 1;
    let r = { ...rybaObject };
    let t = [];
    let c = [];
    let pupu = [];
    let prevReset = [...reset];
    r.organs.map((o, organIndex) => {
      t.push([]);
      c.push([]);
      pupu.push([]);

      o.segments.map((s, index) => {
        t[organIndex].push(s.norm);
        pupu[organIndex].push(false);
        c[organIndex][index] = !prevReset[organIndex][index];
      });
    });

    setText(t);
    setReset(c);

    let h = [];
    r.organs.map((o, organIndex) => {
      h.push([]);
      o.segments.map((s, segmentIndex) => {
        h[organIndex].push([]);
        s.buttons.map((b, buttonIndex) => {
          h[organIndex][segmentIndex].push(false);
        });
      });
    });
    t[rybaLength][0] = r.organs[rybaLength].segments[0].norm;
    setReset(h);
  };

  const updateSettings = useDebouncedCallback((text) => {}, 3000);

  const doSmth = function () {
    console.log("svg кликнуто");
  };

  const showOverlayImages = (boo, imagesArray) => {
    if (boo === true) {
      imagesREF.current = imagesArray;
      setOverlayImages(true);
    }
    if (boo === false) {
      setOverlayImages(false);
    }
  };

  const measureTypeSpeed = useDebouncedCallback((text) => {}, 3000);

  if (error) {
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100vw",
          textAlign: "center",
          marginTop: "200px",
        }}
      >
        <span
          style={{
            fontSize: "28px",
            color: "pink",
          }}
        >
          {error}
        </span>

        <div>
          <img
            src={SadFish}
            alt="Sad Fish"
            style={{
              width: "450px",
              margin: "30px",
              borderRadius: "10px",
              boxShadow: "0px 0px 15px 1px darkgrey",
            }}
          />
        </div>
      </div>
    );
  }
  if (!error) {
    return (
      rybaLoaded &&
      settings && (
        <div className="RYBA4rybaContainer">
          {overlayImages && (
            <OverlayImages
              images={imagesREF.current}
              setOverlayImages={setOverlayImages}
            />
          )}
          {nameReportOpen && (
            <div className="popupContainer">
              <div className="popupContent">
                <p>Название сохраняемого документа</p>
                <TextareaAutosize
                  autoFocus
                  className="inputPromptTextarea"
                  value={reportTitle}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();

                      setNameReportOpen(false);
                    }
                    if (e.key === "Escape") {
                      setNameReportOpen(false);
                      setReportTitle("");
                    }
                  }}
                  onChange={(e) => {
                    setReportTitle(e.target.value);
                    setReportTitle2(e.target.value);
                  }}
                ></TextareaAutosize>
                {/* <p>Дополнительная информация (что угодно; необязательно)</p>
            <TextareaAutosize
              autoFocus
              className="inputPromptTextarea"
              value={info}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  saveReport();
                  setNameReportOpen(false);
                }
                if (e.key === "Escape") {
                  setNameReportOpen(false);
                  setReportTitle("");
                }
              }}
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            ></TextareaAutosize> */}
                <button
                  className="saveChanges"
                  onClick={() => {
                    setNameReportOpen(false);
                    setReportTitle("");
                  }}
                >
                  Сохранить (Enter)
                </button>
                <button
                  className="closeTextEditWindow"
                  onClick={() => {
                    setNameReportOpen(false);
                    setReportTitle("");
                  }}
                >
                  Отмена (Esc)
                </button>
              </div>
            </div>
          )}
          {constructorOpen && (
            <ConstructorRouter
              {...{
                constructorREF,
                setConstructorOpen,
                formState,
                setFormState,
                text,
                setText,
                zaklState,
                setZaklState,
                rybaObject,
                dinamika,
                dinamikaConstructorFunction,
                tut9,
                setTut9,
                setTut10,
                setOrganFocus,
              }}
            />
          )}
          {inputPromptOpen && (
            <InputPromptTutorial
              {...{
                setPromptInputValue,
                setPromptText,
                promptText,
                setInputPromptOpen,
                choicesCount,
                promptButton,
                setChoicesCount,
                organINDEX,
                segmentINDEX,
                buttonINDEX,
                text,
                setText,
                rybaObject,
                checkForDotsZakl,
                setTut12,
                setTut13,
                tut12,
              }}
            />
          )}
          {inputPromptZaklOpen && (
            <InputPromptZaklTutorial
              {...{
                setPromptInputValue,
                setPromptText,
                promptText,
                setInputPromptOpen,
                choicesCount,
                promptButton,
                setChoicesCount,
                organINDEX,
                segmentINDEX,
                buttonINDEX,
                text,
                setText,
                rybaObject,
                setInputPromptZaklOpen,
                tut15,
                setTut15,
                setTut16,
              }}
            />
          )}
          <div
            className="RYBA4ryba"
            key={(keyCounter += 1)}
            style={{
              zoom: zoom,
            }}
          >
            {/* <div>
          <TextareaAutosize
            value={buffer2}
            onChange={(e) => {
              setBuffer2(e.target.value);
            }}
          ></TextareaAutosize>

          <button
            onClick={() => {
              undo2();
              console.log(buffer);
            }}
          >
            Undo
          </button>
          <button
            onClick={() => {
              redo2();
            }}
          >
            redo
          </button>
        </div> */}
            <div className="anchorForNotes">
              {settings && settings.showNotes && (
                <div
                  className="notes"
                  style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    cursor: `${pressed ? "grab" : "pointer"}`,
                  }}
                >
                  <div
                    className="notesTitle"
                    onMouseDown={(e) => {
                      setPressed(true);
                    }}
                    onMouseEnter={() => {
                      setPressed(false);
                    }}
                    onMouseUp={(e) => {
                      setPressed(false);
                    }}
                    onMouseMove={(e) => {
                      if (pressed) {
                        setLeft((l) => l + e.movementX);
                        setTop((t) => t + e.movementY);
                      }
                    }}
                  >
                    Заметки
                  </div>
                  <div className="notesText">
                    <TextareaAutosize
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);

                        window.localStorage.setItem(
                          `notes${id}`,
                          JSON.stringify(e.target.value)
                        );
                      }}
                    ></TextareaAutosize>
                  </div>
                </div>
              )}
            </div>

            {!rybaLoaded && <div class="loader"></div>}
            {rybaLoaded && settings && (
              <>
                {notificationWindowOpen && (
                  <Notification notificationText={notificationText.current} />
                )}
                {rybaObject.organs.length > 2 && (
                  <div className="RYBA4topButtons" key={(keyCounter += 1)}>
                    <div className="RYBA4topButtonsBorder">
                      <div
                        className="RYBA4topButton copyAll"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerCopyToClipboardALL}
                        data-tooltip-id="skopirovatVse"
                        data-tooltip-delay-show="300"
                      >
                        <CopyAll height="96" width="96" />
                        <Tooltip
                          id="skopirovatVse"
                          content="Скопировать протокол и заключение"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div>
                      <div className="RYBA4topButtonsSmallAndTxtFormat">
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            marginLeft: "70px",
                          }}
                        >
                          Работа с шаблоном
                        </span>
                        <div className="RYBA4ropButtonsSmall">
                          <div
                            className="RYBA4topButton"
                            key={(keyCounter += 1)}
                            onClick={clickHandlerCopyToClipboardProtocol}
                            data-tooltip-id="CopyProtocol"
                            data-tooltip-delay-show="300"
                          >
                            <CopyProtocol />
                            <Tooltip
                              id="CopyProtocol"
                              content="Скопировать только протокол"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            key={(keyCounter += 1)}
                            onClick={clickHandlerCopyToClipboardImpression}
                            data-tooltip-id="CopyImp"
                            data-tooltip-delay-show="300"
                          >
                            <CopyImpression />
                            <Tooltip
                              id="CopyImp"
                              content="Скопировать только заключение"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            key={(keyCounter += 1)}
                            onClick={goToDefaultAll}
                            data-tooltip-id="goToNorm"
                            data-tooltip-delay-show="300"
                          >
                            <ResetToNorm height="48" width="48" />
                            <Tooltip
                              id="goToNorm"
                              content="Сбросить всё до нормы"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>

                          {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerDeleteTextALL}
                        data-tooltip-id="clearAll"
                        data-tooltip-delay-show="300"
                      >
                        <DeleteAllText width="48" height="48" />
                        <Tooltip
                          id="clearAll"
                          content="Очистить все поля"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                          <Link
                            onClick={() => {
                              alert("В ознакомительном режиме не работает");
                            }}
                            style={{
                              border: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                            // target="_blank"
                          >
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="editRYba"
                              data-tooltip-delay-show="300"
                            >
                              <EditRyba />
                              <Tooltip
                                id="editRYba"
                                content="Редактировать шаблон"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                          </Link>
                          <div
                            className="RYBA4topButton saveButtonDiv"
                            data-tooltip-id="Save"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              alert("В ознакомительном режиме не работает");
                            }}
                          >
                            <SaveButton />
                            <Tooltip
                              id="Save"
                              content="Сохранить заключение"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                            {saved && (
                              <div className="saveButtonDivOK">
                                <OK />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="dinamikaAndPrimary">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {/* <div
                          className="RYBA4topButton"
                          onClick={(e) => {
                            undo();
                          }}
                          data-tooltip-id="undo"
                          data-tooltip-delay-show="300"
                        >
                          <DOundo />

                          <Tooltip
                            id="undo"
                            content="Отменить действие"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div>
                        <div
                          className="RYBA4topButton"
                          onClick={(e) => {
                            redo();
                          }}
                          data-tooltip-id="redo"
                          data-tooltip-delay-show="300"
                        >
                          <DOredo />
                          <Tooltip
                            id="redo"
                            content="Повторить действие"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div> */}

                            <div
                              className="RYBA4topButton"
                              onClick={(e) => {
                                window.localStorage.setItem(
                                  `zoom`,
                                  JSON.stringify(zoom + 0.05)
                                );
                                setZoom((z) => z + 0.05);
                              }}
                              data-tooltip-id="ZoomIn"
                              data-tooltip-delay-show="300"
                            >
                              <ZoomIn />
                              <Tooltip
                                id="ZoomIn"
                                content="Увеличить мастштаб (приблизить)"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            <div
                              className="RYBA4topButton"
                              onClick={(e) => {
                                window.localStorage.setItem(
                                  `zoom`,
                                  JSON.stringify(zoom - 0.05)
                                );
                                setZoom((z) => z - 0.05);
                              }}
                              data-tooltip-id="ZoomOut"
                              data-tooltip-delay-show="300"
                            >
                              <ZoomOut />
                              <Tooltip
                                id="ZoomOut"
                                content="Уменьшить масштаб (отдалить)"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <button className="dinamika dinActive">
                              Первичное
                            </button>{" "}
                            <Link
                              onClick={() => {
                                alert("В ознакомительном режиме недоступно");
                              }}
                              style={{
                                border: "none",
                                padding: "0px",
                                margin: "0px",
                              }}
                              // target="_blank"
                            >
                              <button className="dinamika dinInactive">
                                Динамика
                              </button>
                            </Link>
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="DOC"
                              data-tooltip-delay-show="300"
                              onClick={() => {
                                exportWORD();
                                if (tut23 === true) {
                                  setTimeout(() => {
                                    setTut23(false);
                                    setTut24(true);
                                  }, 300);
                                }
                              }}
                            >
                              <DOC />
                              <Tooltip
                                id="DOC"
                                content="Экспорт в WORD"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="TXT"
                              data-tooltip-delay-show="300"
                              onClick={() => {
                                exportTXT();
                              }}
                            >
                              <TXT />
                              <Tooltip
                                id="TXT"
                                content="Экспорт в TXT"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            {/* <div
                          className="RYBA4topButton"
                          data-tooltip-id="PDF"
                          data-tooltip-delay-show="300"
                          onClick={() => {
                            // print();
                            // window.open("/exportPDF", "_blank");
                            savePDF();
                          }}
                        >
                          <PDF />
                          <Tooltip
                            id="PDF"
                            content="Экспорт в PDF"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="RYBA4topButtonsBorder2">
                        {/* <p
                      style={{
                        textAlign: "center",
                        color: "grey",
                        fontSize: "0.8rem",
                        paddingTop: "10px",
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "9px",
                      }}
                    >
                      Модальность: {rybaObject && rybaObject.modality}
                    </p> */}
                        <p
                          style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "0.8rem",
                            paddingTop: "10px",
                            margin: "14px",
                            marginBottom: "13px",
                          }}
                        >
                          {/* Протокол:  */}
                          {rybaObject && rybaObject.protocol}
                        </p>
                        <h2 className="RYBA4title" key={(keyCounter += 1)}>
                          {rybaObject.title}
                        </h2>
                      </div>
                      {saved && (
                        <div className="copiedText">
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              marginBottom: "5px",
                              fontWeight: "500",
                            }}
                          >
                            Описание сохранено под названием "{reportTitle2}"
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* <p>Скорость печати: {typeSpeed}</p> */}
                {rybaObject.isChild && rated && (
                  <div className="RYBA4rateRybaDiv">
                    <p>
                      Вы импортировали этот шаблон. Пожалуйста, оцените его от 1
                      до 10, это поможет другим коллегам в выборе!
                    </p>
                    {rateButtons.map((n, index) => (
                      <button
                        parentryba={rybaObject.parentRybaSharedID}
                        style={{
                          background: rateColor[index],
                        }}
                        value={n}
                        onClick={(e) => {}}
                        className="RYBA4rateRyba"
                      >
                        {n}
                      </button>
                    ))}
                  </div>
                )}
                {beenModified && (
                  <div className="RYBA4rateRybaDiv">
                    <p>
                      Структура шаблона и локальной резервной копии не совпадает
                      (скорее всего, Вы отредактировали шаблон на другом
                      устройстве, добавили или удалили орган или раздел),
                      текстовые блоки наверняка съехали; можно сбросить до
                      нормы, и всё восстановится
                    </p>

                    <button
                      onClick={(e) => {
                        setBeenModified(false);
                      }}
                    >
                      Ясно
                    </button>
                  </div>
                )}
                {/* <div className="svgAndImgContainer">
                  <img
                    src={Brain}
                    alt="Brain"
                    style={{
                      width: "320px",
                      height: "320px",
                    }}
                  ></img>
                  {<Shape2 {...{ height: 320, width: 320, doSmth }} />}
                </div> */}

                {rybaObject.organs.length === 3 && (
                  <>
                    <p style={{ textAlign: "center", paddingTop: "20px" }}>
                      Это пустой шаблон. Нажмите кнопку ниже, чтобы начать
                      добавлять в неё содержимое.
                    </p>

                    <Link
                      to={`/editRyba2/${rybaObject._id}`}
                      style={{
                        border: "none",
                        padding: "0px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div
                        className="RYBA4topButton"
                        data-tooltip-id="editRYba2"
                        data-tooltip-delay-show="300"
                        style={{
                          border: "none",
                          padding: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <EditRyba />
                        <Tooltip
                          id="editRYba2"
                          content="Редактировать шаблон"
                          place="bottom"
                          style={{ background: "black", zIndex: "10" }}
                        />
                      </div>
                    </Link>
                  </>
                )}

                <div className="RYBA4segmentsContainer">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {disRyba && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "300px",
                            width: "350px",
                            boxShadow: "0px 0px 45px 1005px rgb(0, 0, 0, 0.8)",
                          }}
                        >
                          Добро пожаловать!
                          <p>
                            Спасибо, что решили уделить своё драгоценное
                            внимание рыбному приложению 😎.{" "}
                          </p>
                          <p>
                            Сейчас покажу, на что способна турборыба, но для
                            начала пробежимся по рыбной анатомии (отставить
                            флешбеки из первого курса):{" "}
                          </p>
                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setDisRyba(false);
                                setTut1(true);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">ПОЕХАЛИ</span>
                          </button>
                        </div>
                        {/* <div className="tutorialArrow">
                          <Arrow height={48} width={48} />
                        </div> */}
                      </div>
                    )}
                    {tut1 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "1000px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Основа шаблона - текстовые разделы (помечены
                            стрелками).
                          </p>
                          <p>
                            Если проводить над ними курсором, они будут
                            выделяться другим цветом
                          </p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut1(false);
                                setTut2(true);
                                setOrganFocus(2000);
                                goToDefaultAll();
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">ДАЛЬШЕ</span>
                          </button>
                        </div>

                        <ArrowComponent
                          top={"-16px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"10px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"40px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"130px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"153px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"175px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"200px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"230px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"259px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"290px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"327px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"357px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"437px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut2 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "912px",

                            top: "300px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Каждый раздел имеет свой текст нормы. Если внести
                            изменения, он тут же подсветится.
                          </p>
                          <p>
                            Поставьте курсор на раздел, помеченный стрелкой, и
                            напечатайте в него что-нибудь
                          </p>

                          {/* <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut1(false);
                                setTut2(true);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">ДАЛЬШЕ</span>
                          </button> */}
                        </div>

                        <ArrowComponent
                          top={"286px"}
                          left={"870px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut3 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "912px",

                            top: "50px",
                            width: "350px",
                          }}
                        >
                          <p>Могёте! Топ!</p>
                          <img
                            style={{
                              width: "250px",
                            }}
                            alt="kjdcn"
                            src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/gif1.gif"
                          />
                          <p>
                            Теперь не надо перечитывать весь протокол, чтобы
                            вычислять, где вы изменили норму, где матку поменяли
                            на простату или наоборот (все там были).
                          </p>
                          <p>Правда, удобно?</p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut3(false);
                                setTut4(true);
                                setOrganFocus(2000);
                                goToDefaultAll();
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              НУ ДОПУСТИМ. ДАЛЬШЕ
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                    {tut4 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "952px",

                            top: "305px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Теперь к самой главной фишке приложения. Нажмите на
                            текстовый раздел, помеченный стрелкой.
                          </p>
                        </div>

                        <ArrowComponent
                          top={"325px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut5 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "930px",

                            top: "-59px",
                            width: "350px",
                          }}
                        >
                          <p>
                            При фокусировке на текстовом разделе появляется
                            боковое меню.
                          </p>
                          <p>
                            Всё просто, как три копейки - кнопки из меню{" "}
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              добавляют текст в текстовый раздел
                            </span>
                            .
                          </p>
                          <p>
                            Вы нашли эностозы, которые вам лень описывать
                            (потому что кому они нужны), но вдруг неопытный
                            онколог будет смотреть исследование и за них
                            зацепится. Нажмите на кнопку, помеченную стрелкой.{" "}
                          </p>
                        </div>

                        <ArrowComponent
                          top={"350px"}
                          left={"1015px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut6 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "930px",

                            top: "-200px",
                            width: "350px",
                          }}
                        >
                          <p>ВИДЕЛИ?</p>
                          <img
                            src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/wow-cat.gif"
                            alt="wow cat"
                          />
                          <p>
                            Пока вы ещё не отошли от шока, давайте добавим
                            атеросклероз аорты и её ветвей.
                          </p>
                          <p>Нажмите на кнопку, помеченную стрелкой.</p>
                        </div>

                        {/* <ArrowComponent
                          top={"348px"}
                          left={"1140px"}
                          rotate={"45deg"}
                        /> */}
                        <ArrowComponent
                          top={"323px"}
                          left={"1265px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut7 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "930px",

                            top: "-155px",
                            width: "350px",
                          }}
                        >
                          <p>ХОБА!</p>
                          <img
                            src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/rona-kick.gif"
                            alt="wow cat"
                          />
                          <p>
                            Видели? Текст добавился{" "}
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              и в описание, и в заключение
                            </span>
                            .
                          </p>
                          <p>
                            Зачем делать лишнюю работу, когда можно не делать?
                          </p>
                          <p>
                            Это всего лишь базовые функции, может, показать
                            пушки посерьёзнее?
                          </p>
                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut7(false);
                                setTut11(true);
                                goToDefaultAll();
                                setOrganFocus(3);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">Удиви меня</span>
                          </button>
                        </div>
                      </div>
                    )}
                    {tut8 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "500px",

                            top: "530px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Вам в потоке прилетел очередной двусторонний
                            гидроторакс, да ещё и{" "}
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              в динамике
                            </span>{" "}
                            🤮.
                          </p>
                          <p>Нажмите на кнопку, помеченную стрелкой.</p>
                        </div>
                        <ArrowComponent
                          top={"776px"}
                          left={"1065px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut9 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "800px",

                            top: "130px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Это кастомный калькулятор объёма гидроторакса,
                            который
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              сделает всё за вас
                            </span>
                            .
                          </p>
                          <p>
                            Ведите в поля любые цифры, главное, больше 6, и
                            нажмите "рассчитать" (или Enter).
                          </p>
                        </div>
                      </div>
                    )}
                    {tut10 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "950px",

                            top: "190px",
                            width: "350px",
                          }}
                        >
                          <p>
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              КАК ВАМ ТАКОЕ?
                            </span>
                          </p>
                          <p>
                            Калькулятор сам считает объёмы и вставляет текст в
                            описание и заключение, вам нужно только ввести
                            циферки.
                          </p>

                          <img
                            alt="reactionGIF"
                            src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/wwe-vince-mcmahon.gif"
                          />

                          <button
                            class="button-82-pushable"
                            style={{
                              marginTop: "7px",
                            }}
                            onClick={() => {
                              setTimeout(() => {
                                setTut10(false);
                                setTut11(false);
                                setTut18(true);
                                goToDefaultAll();
                                setOrganFocus(2000);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Ого, а ещё есть?
                            </span>
                          </button>
                        </div>{" "}
                        <ArrowComponent
                          top={"186px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                        <ArrowComponent
                          top={"466px"}
                          left={"900px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut11 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "950px",

                            top: "-100px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Но ведь не все формулировки могут быть всегда
                            одинаковыми, например, нужно указывать размер
                            чего-то. Как тогда быть, самому лезть потом в текст
                            и вводить, что ли?
                          </p>
                          <p>
                            Да щас, не царское это дело. Нажмите на кнопку,
                            помеченную стрелкой
                          </p>
                        </div>{" "}
                        <ArrowComponent
                          top={"262px"}
                          left={"1052px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut12 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "800px",

                            top: "100px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Для заполнения пропуска вылетает такое вот окно.
                            Курсор уже стоит в текстовой зоне, вам нужно только
                            ввести цифру и нажать Enter.
                          </p>
                        </div>{" "}
                        {/* <ArrowComponent
                          top={"262px"}
                          left={"1052px"}
                          rotate={"45deg"}
                        /> */}
                      </div>
                    )}
                    {tut13 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "940px",

                            top: "-100px",
                            width: "350px",
                          }}
                        >
                          <p>Всё! Фразы в описание и заключение улетели.</p>
                          <p>
                            Но что если вариативность в формулировке может быть
                            не только в размерах?
                          </p>
                          <p>
                            Например, нужно указать сторону или какие-то другие
                            характеристики. Что, печатать самому, что ли?
                          </p>
                          <button
                            class="button-82-pushable"
                            style={{
                              marginTop: "7px",
                            }}
                            onClick={() => {
                              setTimeout(() => {
                                setTut13(false);
                                setTut14(true);
                                goToDefaultAll();
                                setOrganFocus(8);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Вы вообще знаете, кто я? Хватит тратить моё время.
                              Ещё я сам что-то там не печатал
                            </span>
                          </button>
                        </div>{" "}
                        {/* <ArrowComponent
                          top={"262px"}
                          left={"1052px"}
                          rotate={"45deg"}
                        /> */}
                      </div>
                    )}
                    {tut14 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "940px",

                            top: "250px",
                            width: "350px",
                          }}
                        >
                          <p>Оке! Жмите на кнопку.</p>
                        </div>{" "}
                        <ArrowComponent
                          top={"399px"}
                          left={"1192px"}
                          rotate={"45deg"}
                        />
                      </div>
                    )}
                    {tut15 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "760px",

                            top: "100px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Вместо заполнения пробелов вручную можно делать вот
                            такие штуки.
                          </p>
                          <p>Выбирайте варианты и нажимайте на них.</p>
                        </div>{" "}
                      </div>
                    )}
                    {tut16 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "950px",

                            top: "100px",
                            width: "350px",
                          }}
                        >
                          <p>Вот так-то.</p>
                          <p>По шкале от 1 до 10 как вам такое?</p>
                          <img
                            alt="reactionGIF"
                            src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/siteImages/landingPage/wwe-vince-mcmahon.gif"
                          />
                          <p>
                            Теперь хочу рассказать вам кое-что по секрету,
                            только тсс.
                          </p>
                          <button
                            class="button-82-pushable"
                            style={{
                              marginTop: "7px",
                            }}
                            onClick={() => {
                              setTimeout(() => {
                                setTut16(false);
                                setTut17(true);
                                goToDefaultAll();
                                setOrganFocus(8);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Хосссподе, ну что там за секрет
                            </span>
                          </button>
                        </div>{" "}
                      </div>
                    )}
                    {tut17 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "300px",
                            width: "350px",
                            boxShadow: "0px 0px 45px 1005px rgb(0, 0, 0, 0.8)",
                          }}
                        >
                          <p>
                            Всё, что вы сейчас видели,{" "}
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              ПОЛНОСТЬЮ
                            </span>{" "}
                            создано в редакторе приложения, доступного каждому
                            пользователю.
                          </p>
                          <p>
                            То есть все кнопочки, разделы, формулировки, нормы,
                            варианты ответов, их количество и вообще всё{" "}
                            <span
                              style={{
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              можно редактировать или создавать с нуля
                              самостоятельно
                            </span>
                            .
                          </p>

                          <p>
                            Если у вас сложился свой собственный стиль, уже
                            давно работаете, просто создайте свои шаблоны или
                            адаптируйте уже существующие под себя.{" "}
                          </p>
                          <p>
                            Если вы только начинаете и не уверены - есть
                            огромное количество готовых шаблонов (рыбков) с
                            готовыми формулировками под самые распространённые и
                            не очень изменения.
                          </p>
                          <p>И на десерт хочу показать ещё одну фишку</p>
                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut17(false);
                                setTut8(true);
                                setOrganFocus(2);
                                goToDefaultAll();
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Показать фишку
                            </span>
                          </button>
                        </div>
                        {/* <div className="tutorialArrow">
                               <Arrow height={48} width={48} />
                             </div> */}
                      </div>
                    )}
                    {tut18 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "300px",
                            width: "350px",
                            top: "-100px",
                            boxShadow: "0px 0px 45px 1005px rgb(0, 0, 0, 0.8)",
                          }}
                        >
                          <p>Офкос</p>
                          <p>
                            Есть такие калькуляторы для объёма простаты с
                            индексом ПСА, есть расчёты стенозов ВСА по NASCET и
                            ESCT, есть расчёты индексов вымывания надпочечников,
                            и другие, список пополняется с каждым днём.
                          </p>

                          <p>
                            Сейчас приложение в активной разработке и работает в
                            тестовом режиме.{" "}
                          </p>
                          <p>
                            Если вам захотелось испробовать его в деле, можно{" "}
                            <a target="_blank" rel="noreferrer" href="/signup">
                              зарегистрироваться
                            </a>{" "}
                            (занимает 20 секунд), просто указываете ваш
                            ФУНКЦИОНИРУЮЩИЙ почтовый адрес (чтобы смогли
                            сбросить пароль, если забудете) и пароль, и всё.
                          </p>
                          <p>
                            Если есть какие-то идеи или пожелания, можно{" "}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://t.me/+ngo6aWfPCkJlYTA6"
                            >
                              присоединиться к группе в телеге
                            </a>{" "}
                            и поучаствовать в разработке.
                          </p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut18(false);
                                setTut19(true);
                                goToDefaultAll();
                                setOrganFocus(800);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Ну так а с заключением как быть, написал я,
                              дальше-то что?
                            </span>
                          </button>
                        </div>
                        {/* <div className="tutorialArrow">
                               <Arrow height={48} width={48} />
                             </div> */}
                      </div>
                    )}
                    {tut19 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "50px",

                            top: "0px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Есть несколько вариантов для экспорта. Кнопка под
                            стрелкой формирует текст описания с заключением и
                            его копирует. Нажмите её и вставьте куда хотите
                          </p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut19(false);
                                setTut20(true);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">Дальше</span>
                          </button>
                          <ArrowComponent
                            top={"-60px"}
                            left={"-10px"}
                            rotate={"105deg"}
                          />
                        </div>
                      </div>
                    )}
                    {tut20 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "50px",
                            top: "-30px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Эти две кнопки копируют только и описание и только
                            заключение соответственно (если у кого в клинике
                            электронные истории болезни, удобнее вставлять)
                          </p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut20(false);
                                setTut21(true);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">Дальше</span>
                          </button>
                          <ArrowComponent
                            top={"-60px"}
                            left={"40px"}
                            rotate={"135deg"}
                          />
                          <ArrowComponent
                            top={"-60px"}
                            left={"100px"}
                            rotate={"135deg"}
                          />
                        </div>
                      </div>
                    )}
                    {tut21 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "50px",
                            top: "-30px",
                            width: "350px",
                          }}
                        >
                          <p>Эта кнопка сбрасывает весь шаблон до нормы</p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut21(false);
                                setTut22(true);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">Дальше</span>
                          </button>

                          <ArrowComponent
                            top={"-60px"}
                            left={"165px"}
                            rotate={"135deg"}
                          />
                        </div>
                      </div>
                    )}
                    {tut22 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "150px",
                            top: "-30px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Это кнопки для редактирования шаблона и сохранения
                            описания в локальной памяти приложения, сейчас
                            неактивны в режиме демонстрации
                          </p>

                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              setTimeout(() => {
                                setTut22(false);
                                setTut23(true);
                              }, 300);
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">Дальше</span>
                          </button>

                          <ArrowComponent
                            top={"-60px"}
                            left={"195px"}
                            rotate={"135deg"}
                          />
                          <ArrowComponent
                            top={"-60px"}
                            left={"135px"}
                            rotate={"135deg"}
                          />
                        </div>
                      </div>
                    )}
                    {tut23 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "190px",
                            top: "10px",
                            width: "350px",
                          }}
                        >
                          <p>
                            Это кнопки для экспорта в вордовский документ и в
                            обычный блокнот. Вордовский документ формируется с
                            шапкой (название организации), которые можно
                            указывать самостоятельно, с вашим именем и
                            сегодняшней датой.
                          </p>
                          <p>
                            Попробуйте экспортировать шаблон в ворд. Документ
                            улетит в загрузки, в разных браузерах это
                            реализовано по-разному.
                          </p>

                          <ArrowComponent
                            top={"-60px"}
                            left={"175px"}
                            rotate={"135deg"}
                          />
                          <ArrowComponent
                            top={"-60px"}
                            left={"135px"}
                            rotate={"135deg"}
                          />
                        </div>
                      </div>
                    )}
                    {tut24 && (
                      <div className="tutorialContainer">
                        <div
                          className="tutorialWindow color1"
                          style={{
                            left: "300px",
                            width: "350px",
                            boxShadow: "0px 0px 45px 1005px rgb(0, 0, 0, 0.8)",
                          }}
                        >
                          <p>
                            Это конец краткого обзора, спасибо, что уделили
                            время 👍
                          </p>
                          <p>
                            Если вам понравилось, можно начинать пользоваться
                          </p>
                          <a target="_blank" rel="noreferrer" href="/signup">
                            <button class="button-82-pushable">
                              <span class="button-82-shadow"></span>
                              <span class="button-82-edge"></span>
                              <span class="button-82-front text">
                                Начать пользоваться
                              </span>
                            </button>
                          </a>
                          <p>
                            Если хотите предложить свои идеи/пожелания, можно
                            вступить в группу в телеге:
                          </p>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://t.me/+ngo6aWfPCkJlYTA6"
                          >
                            <button class="button-82-pushable">
                              <span class="button-82-shadow"></span>
                              <span class="button-82-edge"></span>
                              <span class="button-82-front text">
                                Участвовать в разработке
                              </span>
                            </button>
                          </a>
                          <p>
                            Если понравилось настолько, что хочется поделиться с
                            кем-то, - кнопка ниже для копирования ссылки:
                          </p>
                          <button
                            class="button-82-pushable"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "https://turboryba.ru"
                              );
                            }}
                          >
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                              Скопировать ссылку
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="imagesContainer" style={{}}>
                      {Object.hasOwn(rybaObject, "shpora") &&
                        rybaObject.shpora.map((shpora, shporaIndex) => (
                          <ImageComponent {...{ shpora, shporaIndex }} />
                        ))}
                    </div>
                  </div>

                  {rybaObject.organs.map((organ, organIndex) => (
                    <div
                      className={`organ ${organIndex === 0 && "topLeft"} ${
                        organIndex === 1 && "bottomLeft marginBottom"
                      } ${organIndex === 2 && "topLeft"} ${
                        organIndex === rL(rybaObject) - 1 &&
                        "bottomLeft marginBottom"
                      } ${
                        organIndex === rL(rybaObject) && "bottomLeft topLeft"
                      }`}
                    >
                      <div
                        className={`organTitle ${
                          organIndex === 0 && "topLeft"
                        } ${organIndex === 1 && "bottomLeft"} ${
                          organIndex === 2 && "topLeft"
                        } ${
                          organIndex === rL(rybaObject) - 1 && "bottomLeft"
                        } ${
                          organIndex === rL(rybaObject) && "bottomLeft topLeft"
                        }`}
                        style={{
                          color: organFocus === organIndex && "white",
                          backgroundColor:
                            organFocus === organIndex && "rgb(16, 16, 16)",
                        }}
                        onClick={() => {
                          setOrganFocus(organIndex);
                        }}
                      >
                        {organ.title}
                      </div>

                      <div className="organSegments">
                        {organ.segments &&
                          organ.segments.map((segment, segmentIndex) => (
                            <div
                              className="sgmnt"
                              key={(keyCounter += 1)}
                              index={segmentIndex}
                            >
                              <TextareaTutorial
                                {...{
                                  organFocus,
                                  setOrganFocus,
                                  organIndex,
                                  segmentIndex,
                                  measureTypeSpeed,
                                  setTypeSpeed,
                                  tut2,
                                  setTut3,
                                  setTut2,
                                  tut4,
                                  setTut4,
                                  setTut5,
                                }}
                              />

                              <div
                                className="segmentButtons"
                                style={{
                                  zIndex:
                                    organFocus === organIndex &&
                                    segmentIndex === 0 &&
                                    "5",
                                  opacity:
                                    organFocus === organIndex &&
                                    segmentIndex === 0 &&
                                    "1",
                                  padding: organ.segments.length === 1 && "0px",
                                }}
                              >
                                <div
                                  className="closeButtonsButton"
                                  onClick={() => {
                                    setOrganFocus(undefined);
                                  }}
                                >
                                  ❌
                                </div>
                                <p className="buttonsOrganTitle">
                                  {organ.title}
                                </p>
                                {organ.segments &&
                                  organ.segments.map(
                                    (segment, segmentIndex) => (
                                      <div className="segmentOne">
                                        <div
                                          className="fieldsetLegend"
                                          style={{
                                            color:
                                              text[organIndex][segmentIndex] ===
                                              rybaObject.organs[organIndex]
                                                .segments[segmentIndex].norm
                                                ? "darkgrey"
                                                : settings.changedSegmentColor,
                                          }}
                                        >
                                          {organ.segments.length !== 1 &&
                                            segment.title}
                                        </div>
                                        {segment &&
                                          segment.buttons.length === 0 && (
                                            <p
                                              style={{
                                                color: "grey",
                                                fontSize: "15px",
                                                paddingLeft: "3px",
                                              }}
                                            >
                                              Кнопок нет
                                            </p>
                                          )}
                                        {segment &&
                                          segment.buttons.map(
                                            (button, buttonIndex) => (
                                              <div>
                                                <ButtonsTutorial
                                                  {...{
                                                    button,
                                                    buttonIndex,
                                                    buttonINDEX,
                                                    organINDEX,
                                                    segmentINDEX,
                                                    zaklRef,
                                                    setConstructorOpen,
                                                    constructorREF,
                                                    organIndex,
                                                    segmentIndex,
                                                    rybaObject,
                                                    setRybaObject,
                                                    id,
                                                    buttonClick,
                                                    reset,
                                                    setReset,
                                                    showOverlayImages,
                                                    tut8,
                                                    setTut8,
                                                    setTut9,
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        {text[organIndex][segmentIndex] !==
                                          rybaObject.organs[organIndex]
                                            .segments[segmentIndex].norm && (
                                          <button
                                            className="goToNorm"
                                            value="Сбросить до нормы"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              let x = [...text];
                                              x[organIndex][segmentIndex] =
                                                segment.norm;
                                              setText(x);
                                            }}
                                          >
                                            Сбросить до нормы
                                          </button>
                                        )}
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )
    );
  }
};

export default RybaTutorial;
