import TextareaAutosize from "react-textarea-autosize";
import Duplicate from "../../svg/Duplicate";
import ArrowUp from "../../svg/ArrowUp";
import ArrowDown from "../../svg/ArrowDown";
import DeleteIcon from "../../svg/DeleteIcon";
import { useState } from "react";
import ChoicesConstructor from "./ChoicesConstructor";
import ChoicesConstructorZakl from "./ChoicesConstructorZakl";

const newOption = {
  title: "Название опции",
  content: "Текст опции. ",
};

const SelectRedactor = ({
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  notification,
  setChoices,
  setChoicesZakl,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [whichRadio, setWhichRadio] = useState();
  const [optionsOpen, setOptionsOpen] = useState(false);

  return (
    <>
      <div className="buttonEditingSegmentRADIOorSELECT">
        <div className="buttonTITLE2">Название кнопки</div>
        <div className="buttonCONTENT2">Текст кнопки</div>
        <div className="buttonZAKL2">В заключение</div>
      </div>
      {button.options.map(
        (option, index) =>
          index > 0 && (
            <>
              <div className="buttonEditingSegmentRADIOorSELECT">
                <div className="buttonTITLE2">
                  <TextareaAutosize
                    value={
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex].options[index].title
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].options[index].title = e.target.value;
                      setRybaObject(r);
                    }}
                  ></TextareaAutosize>
                </div>
                <div className="buttonCONTENT2">
                  <TextareaAutosize
                    placeholder="-"
                    value={
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex].options[index].content
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].options[index].content = e.target.value;
                      setRybaObject(r);
                    }}
                  ></TextareaAutosize>
                </div>

                <div className="buttonZAKL2">
                  <TextareaAutosize
                    placeholder="-"
                    value={
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex].options[index].zakl
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].options[index].zakl = e.target.value;
                      setRybaObject(r);
                    }}
                  ></TextareaAutosize>
                </div>
                <div className="optionsandradioseditdiv">
                  {index > 0 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        const newOption = JSON.parse(
                          JSON.stringify(
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index]
                          )
                        );

                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options.splice(index, 0, newOption);

                        setRybaObject(r);
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        notification("Кнопка скопирована");
                        let r = { ...rybaObject };
                        window.localStorage.setItem(
                          `copiedOPTION`,
                          JSON.stringify(
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index]
                          )
                        );
                      }}
                    >
                      <Duplicate />
                    </button>
                  )}
                  {index > 1 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        let a =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index];
                        let b =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index - 1];
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index] = b;
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index - 1] = a;
                        setRybaObject(r);
                      }}
                    >
                      <ArrowUp />
                    </button>
                  )}
                  {index > 0 && index < button.options.length - 1 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        let a =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index];
                        let b =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index + 1];
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index] = b;
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index + 1] = a;
                        setRybaObject(r);
                      }}
                    >
                      <ArrowDown />
                    </button>
                  )}
                  {rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].options.length > 2 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options.splice(index, 1);

                        setRybaObject(r);
                      }}
                    >
                      <DeleteIcon width={24} height={24} />
                    </button>
                  )}
                </div>
              </div>
              {Object.hasOwn(option, "choices") && (
                <ChoicesConstructor
                  buttonItself={option}
                  setChoices={setChoices}
                  buttonType={"select"}
                  organIndex={organIndex}
                  segmentIndex={segmentIndex}
                  buttonIndex={buttonIndex}
                  radioIndex={undefined}
                  optionIndex={index}
                />
              )}
              {Object.hasOwn(option, "choicesZakl") && (
                <ChoicesConstructorZakl
                  buttonItself={option}
                  setChoicesZakl={setChoicesZakl}
                  buttonType={"select"}
                  organIndex={organIndex}
                  segmentIndex={segmentIndex}
                  buttonIndex={buttonIndex}
                  radioIndex={undefined}
                  optionIndex={index}
                />
              )}
            </>
          )
      )}
      <div>
        <button
          onClick={() => {
            let r = { ...rybaObject };
            let nr = { ...newOption };
            if (
              Object.hasOwn(
                r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
                  .options[0],
                "choices"
              )
            ) {
              nr.choices = [];
            }
            if (
              Object.hasOwn(
                r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
                  .options[0],
                "choicesZakl"
              )
            ) {
              nr.choicesZakl = [];
            }
            r.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].options.push(nr);
            setRybaObject(r);
          }}
        >
          ➕
        </button>
      </div>
    </>
  );
};
export default SelectRedactor;
