import TextareaAutosize from "react-textarea-autosize";
import { protocolsMRI } from "../../data/protocolsMRI";
import { protocolsCT } from "../../data/protocolsCT";
import { protocolsUS } from "../../data/protocolsUS";
import { protocolsMAMMOGRAPHY } from "../../data/protocolsMAMMOGRAPHY";
import { protocolsXRAY } from "../../data/protocolsXRAY";
import { protocolsPET } from "../../data/protocolsPET";
const RybaCreate = ({
  closeRybaCreateWindowOnclick,
  onSelectOptionChangeHandler,
  titleEditHandler,
  rybaTitleEdit,
  createNewRybaRequest,
  modalityState,
  protocol,
}) => {
  let p = 0;

  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      createNewRybaRequest();
    }
    if (e.key === "Escape") {
      closeRybaCreateWindowOnclick();
    }
  };

  return (
    <div className="popupContainer" key={(p += 1)}>
      <div
        className="popupContent"
        key={(p += 1)}
        style={{
          position: "fixed",
        }}
      >
        <h1 key={(p += 1)}>Создание новой рыбы</h1>
        <p key={(p += 1)}>Название нового шаблона</p>
        <TextareaAutosize
          onChange={titleEditHandler}
          value={rybaTitleEdit}
          key={(p += 1)}
          autoFocus
          onKeyDown={submitEnter}
        ></TextareaAutosize>
        {/* <select
          onChange={onSelectOptionChangeHandler}
          name="selectModality"
          id="1"
        >
          <option value="">Выберите модальность</option>
          <option value="КТ">КТ</option>
          <option value="МРТ">МРТ</option>
          <option value="Классика">Классика</option>
          <option value="УЗИ">УЗИ</option>
          <option value="Маммография">Маммография</option>
          <option value="ПЭТ">ПЭТ</option>
        </select> */}
        {modalityState === "КТ" && (
          <select
            className="protocolSelect"
            onChange={(e) => {
              protocol.current = e.target.value;
              console.log(protocol.current);
            }}
            name="selectProtocol"
            id="2"
          >
            <option value="">Выберите протокол</option>
            {protocolsCT.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "МРТ" && (
          <select
            className="protocolSelect"
            onChange={(e) => {
              protocol.current = e.target.value;
              console.log(protocol.current);
            }}
            name="selectProtocol"
            id="3"
          >
            <option value="">Выберите протокол</option>
            {protocolsMRI.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "Классика" && (
          <select
            className="protocolSelect"
            onChange={(e) => {
              protocol.current = e.target.value;
              console.log(protocol.current);
            }}
            name="selectProtocol"
            id="4"
          >
            <option value="">Выберите протокол</option>
            {protocolsXRAY.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "УЗИ" && (
          <select
            className="protocolSelect"
            onChange={(e) => {
              protocol.current = e.target.value;
              console.log(protocol.current);
            }}
            name="selectProtocol"
            id="5"
          >
            <option value="">Выберите протокол</option>
            {protocolsUS.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "Маммография" && (
          <select
            className="protocolSelect"
            onChange={(e) => {
              protocol.current = e.target.value;
              console.log(protocol.current);
            }}
            name="selectProtocol"
            id="6"
          >
            <option value="">Выберите протокол</option>
            {protocolsMAMMOGRAPHY.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "ПЭТ" && (
          <select
            className="protocolSelect"
            onChange={(e) => {
              protocol.current = e.target.value;
              console.log(protocol.current);
            }}
            name="selectProtocol"
            id="2"
          >
            <option value="">Выберите протокол</option>
            {protocolsPET.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        <button className="saveChanges" onClick={createNewRybaRequest}>
          Создать шаблон
        </button>
        <button
          className="closeTextEditWindow"
          onClick={closeRybaCreateWindowOnclick}
        >
          Закрыть без сохранения
        </button>
      </div>
    </div>
  );
};
export default RybaCreate;
