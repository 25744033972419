import Img from "./Img";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import DeleteIcon from "../svg/DeleteIcon";
import { useAuthContext } from "../../hooks/useAuthContext";

const ImagesToTheRightRedactor = ({
  rybaObject,
  setRybaObject,
  setRightImagesRedactor,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { user } = useAuthContext();

  const newShpora = {
    title: "Новая шпора",
    text: "Текст новой шпоры",
    images: [],
  };

  const uploadImageToShpora = async (e, image, shporaIndex) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    console.log(formData);
    const response = await fetch(`https://api.defaultuploader.com/v1/upload`, {
      method: "POST",
      headers: {
        Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
      },
      body: formData,
    });

    console.log(response);
    const json = await response.json();
    if (response.ok) {
      json[0].author = user.id;
      console.log(json[0]);
      console.log(response);
      let r = { ...rybaObject };
      r.shpora[shporaIndex].images.push(json[0]);
      setRybaObject(r);
      setLoading(false);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  const deleteOnlyImage = async (pathOriginal, author) => {
    if (author !== user.id) return;
    await fetch(`https://api.defaultuploader.com/v1/upload/${pathOriginal}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
      },
    });
  };

  const deleteImageToShpora = async (
    e,
    pathOriginal,
    img,
    index,
    shporaIndex
  ) => {
    if (img.author !== user.id) {
      let r = { ...rybaObject };
      r.shpora[shporaIndex].images.splice(index, 1);
      setRybaObject(r);
      setDeleting(false);
      return;
    }
    const response = await fetch(
      `https://api.defaultuploader.com/v1/upload/${pathOriginal}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
        },
      }
    );

    const json = await response;
    if (response.ok) {
      console.log("успешно удалено, джесон: ", json);
      console.log("успешно удалено, сам респонс: ", response);
      let r = { ...rybaObject };
      r.shpora[shporaIndex].images.splice(index, 1);
      setRybaObject(r);
      setDeleting(false);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  return (
    <div className="ImagesToTheRightContainer">
      <div
        style={{
          margin: "6px",
        }}
      >
        <button
          onClick={() => {
            let r = { ...rybaObject };
            r.shpora.push(newShpora);
            setRybaObject(r);
          }}
        >
          Добавить новую шпору
        </button>{" "}
        <button
          onClick={() => {
            setRightImagesRedactor(false);
          }}
        >
          Закрыть
        </button>
      </div>

      {rybaObject.shpora.map((shpora, shporaIndex) => (
        <div className="shporaSegment">
          <button
            onClick={async () => {
              await shpora.images.map((s, i) =>
                deleteOnlyImage(s.s3PathOriginal, s.author)
              );
              let r = { ...rybaObject };
              r.shpora.splice(shporaIndex, 1);
              setRybaObject(r);
            }}
            style={{
              position: "absolute",
              right: "4px",
              top: "4px",
            }}
          >
            Удалить шпору
          </button>
          <TextareaAutosize
            className="shporaTitle"
            value={shpora.title}
            onChange={(e) => {
              let r = { ...rybaObject };
              r.shpora[shporaIndex].title = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>

          <TextareaAutosize
            className="shporaText"
            value={shpora.text}
            onChange={(e) => {
              let r = { ...rybaObject };
              r.shpora[shporaIndex].text = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>
          <div
            className="choices"
            style={{
              background: "transparent",
            }}
          >
            <div
              style={{
                marginBottom: "5px",
              }}
            >
              Картинки шпоры:
            </div>
            <div className="imagesContainter">
              {rybaObject.shpora[shporaIndex].images.map((image, index) => (
                <div className="choicesSegmentContainer">
                  {deleting && <div className="loaderSpinner"></div>}
                  {!loadingDelete && (
                    <div
                      className="buttonImageContainer"
                      style={{
                        display: deleting && "none",
                      }}
                    >
                      <div
                        className="buttonImageDelete"
                        onClick={(e) => {
                          setDeleting(true);
                          deleteImageToShpora(
                            e,
                            image.s3PathOriginal,
                            image,
                            index,
                            shporaIndex
                          );
                        }}
                      >
                        <DeleteIcon width={24} height={24} />
                      </div>
                      <Img src={image.src} />
                    </div>
                  )}
                  {loadingDelete && <div className="loaderSpinner"></div>}
                </div>
              ))}
            </div>
            <div>
              <div className="imagesUploadDiv">
                Добавить картинку (можно перетащить)
                {!loading && (
                  <input
                    className="imageInput"
                    accept="image/png, image/jpeg"
                    type="file"
                    name="file"
                    onChange={(e) => {
                      uploadImageToShpora(e, e.target.files[0], shporaIndex);
                    }}
                  />
                )}
                {loading && <div className="loaderSpinner"></div>}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ImagesToTheRightRedactor;
