import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import img5 from "../images/img5.png";
import img6 from "../images/img6.png";

const Article0 = () => {
  return (
    <div className="article">
      <h1>О приложении</h1>
      <p
        style={{
          fontSize: "1.2rem",
          marginBottom: "30px",
        }}
      >
        <span className="bold lightblue">Турборыба (с)</span> - это
        веб-приложение (открывается через браузер с любого компьютера) для
        супербыстрого, молниеносного, МОМЕНТАЛЬНОГО описания рентгенологических
        исследований.
      </p>
      <div className="howHORIZONTAL">
        <div className="howLEFT"></div>
        <div className="howRIGHT"></div>
      </div>
      <div className="howHORIZONTAL">
        <div className="howLEFT">
          <p>
            В основе приложения лежит{" "}
            <span className="yellow">текстовый блок</span>,
          </p>
        </div>
        <div className="howRIGHT">
          {" "}
          <img
            src={img1}
            alt="img1"
            style={{
              width: "700px",
            }}
          ></img>
        </div>
      </div>
      <div className="howHORIZONTAL">
        <div className="howLEFT">
          <p>
            к которому привязаны <span className="yellow">кнопки</span>.
          </p>
        </div>
        <div className="howRIGHT">
          <img
            src={img2}
            alt="img1"
            style={{
              width: "700px",
            }}
          ></img>
        </div>
      </div>
      <div className="howHORIZONTAL">
        <div className="howLEFT">
          <p>
            При нажатии кнопки к текстовому блоку добавится текст, который вы
            обозначили сами. Текст вставляется одновременно и в{" "}
            <span className="red">ОПИСАНИЕ</span>, и в{" "}
            <span className="red">ЗАКЛЮЧЕНИЕ</span>.
          </p>
        </div>
        <div className="howRIGHT">
          {" "}
          <img
            src={img3}
            alt="img1"
            style={{
              width: "700px",
            }}
          ></img>
        </div>
      </div>
      <div className="howHORIZONTAL">
        <div className="howLEFT">
          {" "}
          <p>
            Шаблоны состоят из множества текстовых блоков, к каждому из которых
            относится свой уникальный набор кнопок.
          </p>
        </div>
        <div className="howRIGHT">
          {" "}
          <img
            src={img4}
            alt="img1"
            style={{
              width: "700px",
            }}
          ></img>{" "}
          <img
            src={img5}
            alt="img1"
            style={{
              width: "700px",
            }}
          ></img>{" "}
          <img
            src={img6}
            alt="img1"
            style={{
              width: "700px",
            }}
          ></img>
        </div>
      </div>

      <p>
        Все кнопки <span className="yellow">ПОЛНОСТЬЮ КАСТОМИЗИРУЕМЫЕ</span>,
        можно менять название, описание, заключение, добавлять их сколько угодно
        много.
      </p>
      <p>
        Можно создавать свои шаблоны с нуля, а можно{" "}
        <span className="red">импортировать уже готовые шаблоны</span>, чтобы
        быстрее нарастить свои привычные формулировки на уже готовый скелет.
        Любые формулировки, которые вам не по душе, можно тут же поправить в
        редакторе.
      </p>
      <p>Когда описание готово, у вас есть несколько вариантов его экспорта:</p>

      <p
        style={{
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        - можно нажимать кнопки копирования, они сформируют текст описания и
        скопируют его в буфер обмена; дальше можно вставлять его куда угодно
      </p>
      <p
        style={{
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        - можно копировать описание с заключением, можно ТОЛЬКО заключение или
        ТОЛЬКО описание (удобно для организаций, где есть свои электронные
        шляпы, в которые нужно вставлять их отдельно)
      </p>

      <p
        style={{
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        - можно экспортировать описание в DOCX (обычный ворд документ); в
        настройках приложения можно указать название организации, в которой
        работаете, своё ФИО, и приложение сформирует готовый ворд, останется
        только внести данные пациента
      </p>
    </div>
  );
};
export default Article0;
