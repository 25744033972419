import * as React from "react";
const Arrow = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className="icon flat-line"
    data-name="Flat Line"
    viewBox="0 0 24 24"
  >
    <path
      d="m19.71 7.12-7.51 7.5 2.45 1.32a1 1 0 0 1-.16 1.85L5.28 20A1.05 1.05 0 0 1 4 18.72l2.21-9.21a1 1 0 0 1 1.85-.16l1.32 2.45 7.5-7.51a1 1 0 0 1 1.41 0l1.42 1.42a1 1 0 0 1 0 1.41Z"
      style={{
        fill: "#ffed7a",
        strokeWidth: 2,
      }}
    />
    <path
      d="m19.71 7.12-7.51 7.5 2.45 1.32a1 1 0 0 1-.16 1.85L5.28 20A1.05 1.05 0 0 1 4 18.72l2.21-9.21a1 1 0 0 1 1.85-.16l1.32 2.45 7.5-7.51a1 1 0 0 1 1.41 0l1.42 1.42a1 1 0 0 1 0 1.41Z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
  </svg>
);
export default Arrow;
